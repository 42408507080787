import { Autocomplete, TextField } from "@mui/material";
import { useRequest } from "ahooks";
import { Controller } from "react-hook-form";
import { Instructor as InstructorDB } from "../../hooks/model";

function SelectInstructor({ control, name, error, helperText, required, label }) {
  const { data, loading } = useRequest(InstructorDB.getAll);

  const dataSelect = !data
    ? []
    : data?.data?.map((e) => ({
        id: e?.id,
        label: e?.name,
      }));

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          disablePortal
          id='combo-box-student'
          value={value}
          onChange={(option, e) => {
            onChange(e);
          }}
          options={dataSelect}
          isOptionEqualToValue={(option, e) => option?.id === e?.id}
          getOptionLabel={(option) => option.label}
          style={{ width: "100%" }}
          loading={loading}
          size='small'
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.label}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              required={required}
              error={error}
              helperText={helperText}
              {...params}
              placeholder='instructor'
              label={label !== null && label !== undefined ? label : "Instructor"}
            />
          )}
        />
      )}
    />
  );
}

export default SelectInstructor;
