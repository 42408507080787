import Init from "./init";
import { randomFn } from "./randomFn";

class Dashboard {
  static initPath = (path) => {
    return `/api/dashboard${path}`;
  };

  static getTotalStudent = async () => {
    const res = await Init.fnInit(this.initPath("/getTotalStudent"));

    return res;
  };

  static getTotalPending = async () => {
    const res = await Init.fnInit(this.initPath("/getTotalPending"));

    return res;
  };

  static getTotalInstructor = async () => {
    const res = await Init.fnInit(this.initPath("/getTotalInstructor"));

    return res;
  };

  static getTotalCourse = async () => {
    const res = await Init.fnInit(this.initPath("/getTotalCourse"));

    return res;
  };

  static getTotalLocation = async () => {
    const res = await Init.fnInit(this.initPath("/getTotalLocation"));

    return res;
  };

  static getTotalPool = async () => {
    const res = await Init.fnInit(this.initPath("/getTotalPool"));

    return res;
  };

  static getTotalRegister30days = async () => {
    const res = await Init.fnInit(this.initPath("/getTotalRegister30days"));

    return res;
  };

  static getTotalConfirmed30days = async () => {
    const res = await Init.fnInit(this.initPath("/getTotalConfirmed30days"));

    return res;
  };

  static getStudentChartByMonth = async ({ month, year }) => {
    const data = { month, year };
    const res = await Init.fnInit(this.initPath("/getStudentChartByMonth"), data);

    // wait...
    await randomFn();

    return res;
  };

  static getTodaySchedulePaginator = async ({ page, pageSize, keyword, date }) => {
    const data = { page, pageSize, keyword, date };
    const res = await Init.fnInit(this.initPath("/getTodaySchedulePaginator"), data);

    return res;
  };
}

export default Dashboard;
