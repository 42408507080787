import { TextField } from "@mui/material";
import React from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Controller } from "react-hook-form";

function ControlDropDatePicker({
  control,
  name,
  error,
  helperText,
  required,
  minDate,
  label,
  placeholder,
  disabled,
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            label={label !== null && label !== undefined ? label : "Date"}
            inputFormat='DD/MM/yyyy'
            clearable
            value={value}
            disabled={disabled || false}
            minDate={minDate || null}
            onChange={onChange}
            // OpenPickerButtonProps={{
            //   slot
            // }}
            renderInput={(params) => (
              <TextField
                style={{ width: "100%" }}
                {...params}
                size='small'
                error={error}
                helperText={helperText}
                required={required}
                placeholder={placeholder || "date"}
              />
            )}
          />
        </LocalizationProvider>
      )}
    />
  );
}

export default ControlDropDatePicker;
