import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import React, { useMemo } from "react";
import { TiFolderDelete } from "react-icons/ti";
import { GrStatusWarning } from "react-icons/gr";
import { BsCheckLg } from "react-icons/bs";
import { LoadingButton } from "@mui/lab";

function ControlDialog({ open, setOpen, handleYes, description, title, type, isLoading }) {
  const icon = useMemo(() => {
    switch (type) {
      case "delete":
        return <TiFolderDelete size={25} color='red' />;
      case "warning":
        return <GrStatusWarning color='yellow' />;
      case "success":
        return <BsCheckLg color='green' />;
      default:
        return null;
    }
  }, [type]);

  const getColor = () => {
    switch (type) {
      case "delete":
        return "error";
      case "warning":
        return "primary";
      case "success":
        return "success";
      default:
        return null;
    }
  };

  const handleOk = () => {
    handleYes();
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <Stack direction='row' gap={1} alignItems='center'>
          {icon}
          {title}
        </Stack>
      </DialogTitle>
      <DialogContent>
        <DialogContentText fontFamily='KhmerOSContent'>{description}</DialogContentText>
      </DialogContent>
      <DialogActions style={{ padding: 20 }}>
        <Button className='ssc_text_field' autoFocus onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <LoadingButton
          className='ssc_text_field'
          variant='outlined'
          loading={isLoading}
          color={getColor()}
          onClick={handleOk}
        >
          Yes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default ControlDialog;
