import { Box, Chip, Divider, Grid, IconButton, Skeleton, Stack, Typography } from "@mui/material";
import { useRequest } from "ahooks";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import { animateScroll as scroll } from "react-scroll";

import { PhotoView } from "react-photo-view";
import { BsFillCameraFill, BsFillPersonVcardFill } from "react-icons/bs";
import { AiFillEdit } from "react-icons/ai";
import { useSetRecoilState } from "recoil";
import { LoadingButton } from "@mui/lab";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Student as StudentDB } from "../../hooks/model";
import "./Static.scss";
import Payment from "./payment/Payment";
import Schedule from "./schedule/Schedule";
import UpdateStudent from "./updatestudent/UpdateStudent";
import UpdateImage from "./modal/UpdateImage";
import { StudentDetail } from "../../common";
import { ResetButton, StudentProfile as StudentProfileRecoil } from "../../recoils";
import { getImageUrl } from "../../utils/getUrl";
import { BreadCrumb } from "../../components";
import CompleteLesson from "../../common/studentprofile/CompleteLesson";
import { openNewTab } from "../../utils/function";
// import Fancybox from "../../common/shared/fancyBox";

function StudentProfile() {
  const { studentId } = useParams();
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openUpdateImage, setOpenUpdateImage] = useState(false);
  const setResetState = useSetRecoilState(ResetButton.resetButtonState);
  const setStudentProfile = useSetRecoilState(StudentProfileRecoil.studentProfileState);

  const { loading, data, refresh } = useRequest(StudentDB.getOne, {
    defaultParams: [{ studentId }],
    cacheKey: `studentProfileCache-${studentId}`,
    onSuccess: (res) => {
      if (res?.status) {
        setStudentProfile(res?.data);
      }
    },
  });

  const studentData = data?.data;

  useEffect(() => {
    setResetState((prev) => ({
      ...prev,
      isIn: false,
    }));
  }, []);

  return (
    <Box>
      <UpdateStudent
        open={openUpdate}
        setOpen={setOpenUpdate}
        fetchData={refresh}
        selectData={{ ...studentData, studentId }}
      />

      <UpdateImage
        setOpen={setOpenUpdateImage}
        open={openUpdateImage}
        fetchData={refresh}
        selectData={{ ...studentData, studentId }}
      />

      <Box className='ssc_profile_container' style={{ padding: 20 }}>
        <Grid container style={{ marginBottom: 18 }}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <BreadCrumb
              pageTitle={`SWIMMER / ${studentData?.st_name?.toUpperCase()}`}
              isLoading={loading}
            />
          </Grid>
          <Grid item xs={0} sm={6} md={6} lg={6} />
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <Box className='student-profile-detail'>
              <Box className='student-profile-image-container'>
                <Stack
                  className='student-profile-edit-btn'
                  direction={{ xs: "column", lg: "row" }}
                  gap={2}
                >
                  <LoadingButton
                    color='success'
                    size='small'
                    loading={loading}
                    variant='outlined'
                    onClick={() => openNewTab({ url: `/student-card/${studentData?.student_id}` })}
                    startIcon={<BsFillPersonVcardFill />}
                  >
                    Card
                  </LoadingButton>
                  <LoadingButton
                    color='info'
                    size='small'
                    loading={loading}
                    variant='outlined'
                    onClick={() => setOpenUpdate(true)}
                    startIcon={<AiFillEdit />}
                  >
                    Edit
                  </LoadingButton>
                </Stack>

                <Box className='image-box'>
                  <PhotoView src={getImageUrl(studentData?.profile_img)}>
                    <LazyLoadImage
                      alt=''
                      className='student-profile-image'
                      effect='blur'
                      src={getImageUrl(studentData?.profile_img)}
                      placeholderSrc={getImageUrl(studentData?.profile_img)}
                    />
                  </PhotoView>
                  {/* <Fancybox
                    options={{
                      Carousel: {
                        infinite: false,
                      },
                      Images: {
                        zoom: true,
                      },
                    }}
                  >
                    <a data-fancybox='gallery' href={getImageUrl(studentData?.profile_img)}>
                      <LazyLoadImage
                        alt=''
                        className='student-profile-image'
                        effect='blur'
                        src={getImageUrl(studentData?.profile_img)}
                        placeholderSrc={getImageUrl(studentData?.profile_img)}
                      />
                    </a>
                  </Fancybox> */}

                  <IconButton
                    aria-label='navigator-to'
                    className='float-navigator-btn'
                    onClick={() => setOpenUpdateImage(true)}
                  >
                    <BsFillCameraFill />
                  </IconButton>
                </Box>
              </Box>
              <Box style={{ marginTop: 15 }}>
                {!loading ? (
                  <Typography className='student-name' variant='h5' gutterBottom>
                    {studentData?.st_name}{" "}
                    <Chip
                      label={studentData?.st_status}
                      color={studentData?.st_status === "Active" ? "success" : "error"}
                    />
                  </Typography>
                ) : (
                  <Skeleton variant='text' width={300} height={40} />
                )}
              </Box>
              <Box style={{ marginTop: 20 }}>
                <StudentDetail dataSource={studentData} loading={loading} />
              </Box>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              <Box>
                <CompleteLesson loading={loading} />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={5} lg={5} className='student-profile-detail'>
            <Payment
              studentId={studentId}
              courseId={studentData?.course_id}
              startDate={studentData?.start_date}
              endDate={studentData?.end_date}
            />

            <Schedule studentId={studentId} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default StudentProfile;
