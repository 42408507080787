import { atom } from "recoil";

export const resetButtonState = atom({
  key: "resetButton",
  default: {
    routeKey: "",
    isIn: false,
    action: () => {},
  },
});
