import "./Static.scss";

export default function ColorRateNumber({ rate, textBold }) {
  const rateNumber = parseInt(rate || 0, 10);
  return (
    <div
      className={rateNumber > 4 ? "success-number" : "error-number"}
      style={{ fontWeight: textBold ? "bold" : "normal" }}
    >
      {rateNumber}
    </div>
  );
}
