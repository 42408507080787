import { Grid } from "@mui/material";
import React from "react";
import { GiBank } from "react-icons/gi";

function Banks() {
  return (
    <Grid container>
      <Grid item xs={6}>
        {/* ABA BANK */}
        <span className='info-text'>
          <b>
            <GiBank /> The Advanced Bank of Asia Limited (ABA)
          </b>
        </span>
        <br />
        <span className='info-text'>
          &emsp;&nbsp; Account Name: <b>Survival Swim Club</b>
        </span>
        <br />
        <span className='info-text'>
          &emsp;&nbsp; Account Number: <b>004 207 190</b>
        </span>
      </Grid>
      <Grid item xs={6}>
        {/* ACLEDA BANK */}
        {/* <span className='info-text'>
          <b>
            <GiBank /> ACLEDA Bank
          </b>
        </span>
        <br />
        <span className='info-text'>
          &emsp;&nbsp;Account Name: <b>Survival Swim Club</b>
        </span>
        <br />
        <span className='info-text'>
          &emsp;&nbsp;Account Number (Riel): <b>3475-04684685-23</b>
        </span>
        <br />
        <span className='info-text'>
          &emsp;&nbsp;Account Number (USD): <b>3475-04684685-12</b>
        </span> */}
      </Grid>
      <br />
    </Grid>
  );
}

export default Banks;
