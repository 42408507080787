import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PuffLoader } from "react-spinners";
import { useRequest } from "ahooks";
// import { useReactToPrint } from "react-to-print";
import downloadjs from "downloadjs";
import html2canvas from "html2canvas";
import moment from "moment";
import "./Static.scss";
import { StudentCard } from "../../assets";
import { Student as StudentDB } from "../../hooks/model";
import { getImageUrl } from "../../utils/getUrl";
import { generateAge } from "../../utils/function";

function StudentCardPage() {
  const { id } = useParams();
  // const printRef = useRef();
  const [studentData, setStudentData] = useState(null);
  // please change to create user
  const { loading, run } = useRequest(StudentDB.getOne, {
    manual: true,
    onSuccess: (data) => {
      if (data?.status) {
        setStudentData(data?.data);
      }
    },
  });

  useEffect(() => {
    if (id) {
      run({
        studentId: id,
      });
    }
  }, [id]);

  // useEffect(() => {
  //   document.addEventListener("contextmenu", (e) => {
  //     e.preventDefault();
  //   });
  //   return () => {
  //     document.removeEventListener("contextmenu");
  //   };
  // }, []);

  const handleCaptureClick = async () => {
    const printContent = document.querySelector(".print-st-body");
    if (!printContent) return;

    const canvas = await html2canvas(printContent, {
      allowTaint: true,
      useCORS: true,
      scale: 15,
    });
    const dataURL = canvas.toDataURL("image/png");
    downloadjs(dataURL, `${studentData?.st_name?.split(" ").join("-")}-ID-Card.png`, "image/png");
  };

  useEffect(() => {
    if (studentData) {
      handleCaptureClick();
    }
  }, [studentData, handleCaptureClick]);

  const getAge = () => {
    const allAges = generateAge({ birthdate: studentData?.birthdate });
    if (allAges?.years > 0) {
      return `${allAges?.years} years`;
    }
    // if (allAges?.months > 0) {
    return `${allAges?.months} months`;
    // }
  };

  if (loading)
    return (
      <div className='loading-container'>
        <PuffLoader color='#FFFFFF' />
      </div>
    );

  if (!studentData)
    return (
      <div className='loading-container' style={{ color: "#FFFFFF" }}>
        <div style={{ textAlign: "center" }}>
          <h3>Something wrong!</h3>Please make sure you generate staff card.
        </div>
      </div>
    );

  return (
    <div className='ssc_st_card_container'>
      <div className='print-st-body'>
        <div style={{ position: "relative" }}>
          <img
            style={{
              width: 366,
              position: "relative",
            }}
            src={StudentCard}
            // className='image-card'
            alt='image23-card'
            draggable={false}
          />

          <div className='image-student'>
            <div className='image-worker'>
              {studentData?.image !== "instructor-profile/default.png" ? (
                <img
                  className='worker_image'
                  draggable={false}
                  src={getImageUrl(studentData?.profile_img)}
                  alt=''
                />
              ) : null}
            </div>
          </div>

          <div className='st-main-name'>{studentData?.st_name}</div>

          <div className='st-text-container'>
            {/* <div>{studentData?.st_name}</div> */}
            <div>{studentData?.ssc_id}</div>
            <div>{studentData?.location?.location}</div>
            <div>{studentData?.st_gender}</div>
            <div>{getAge()}</div>
            <div>{moment(studentData?.birthdate).format("DD-MMM-YYYY")}</div>
            <div>{studentData?.communicate_lang}</div>
            <div>{studentData?.contactnumber}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentCardPage;
