import { Autocomplete, TextField } from "@mui/material";
import { useRequest } from "ahooks";
import { Controller } from "react-hook-form";
import { getAllLocation } from "../../hooks/db";

import "./Static.scss";

function SelectLocation({ control, name, error, helperText, required, label, disableClearable }) {
  const { data, loading } = useRequest(getAllLocation);

  const dataSelect = !data
    ? []
    : data?.data?.map((e) => ({
        id: e?.id,
        label: e?.location,
      }));

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <Autocomplete
            disablePortal
            id='combo-box-location'
            className='custom-autocomplete-location'
            value={value}
            size='small'
            disableClearable={disableClearable}
            options={dataSelect}
            onChange={(option, e) => {
              onChange(e);
            }}
            isOptionEqualToValue={(option, e) => option?.id === e?.id}
            getOptionLabel={(option) => `${option.label}`}
            style={{ width: "100%" }}
            loading={loading}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.label}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                error={error}
                helperText={helperText}
                required={required}
                placeholder='location'
                {...params}
                label={label !== null && label !== undefined ? label : "Location"}
              />
            )}
          />
        );
      }}
    />
  );
}

export default SelectLocation;
