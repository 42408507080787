import { Divider, Grid } from "@mui/material";
import moment from "moment";
import React from "react";
import { RowData } from "../../components";
import { generateAge } from "../../utils/function";
import KhmerFlag from "../../assets/images/Khmer.png";
import EnglishFlag from "../../assets/images/English.png";

function StudentDetail({ dataSource, loading }) {
  const studentData = dataSource;
  return (
    <>
      <Grid container columnSpacing={2}>
        <RowData title='ID' value={studentData?.ssc_id} loading={loading} />
        <RowData title='Location' value={studentData?.location?.location} loading={loading} />
        <RowData title='Gender' value={studentData?.st_gender} loading={loading} />
        <RowData
          title='Age'
          value={`${generateAge({ birthdate: studentData?.birthdate })?.years || 0} years, ${
            generateAge({ birthdate: studentData?.birthdate })?.months || 0
          } months`}
          loading={loading}
        />
        <RowData
          title='Date of birth'
          value={moment(studentData?.birthdate).format("DD-MMM-YYYY")}
          loading={loading}
        />

        <RowData
          title='Language'
          value={
            studentData?.communicate_lang ? (
              <img
                src={studentData?.communicate_lang === "Khmer" ? KhmerFlag : EnglishFlag}
                className='language-image'
                alt=''
                style={{ width: 50, objectFit: "cover" }}
              />
            ) : (
              "---"
            )
          }
          loading={loading}
        />
      </Grid>

      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
      <Grid container columnSpacing={2}>
        <RowData
          title='Registered date'
          value={moment(studentData?.reg_date).format("DD-MMM-YYYY HH:mm")}
          loading={loading}
        />
        <RowData
          title='Start date'
          value={
            studentData?.start_date !== "0000-00-00"
              ? moment(studentData?.start_date).format("DD-MMM-YYYY")
              : "---"
          }
          loading={loading}
        />
        <RowData
          title='End date'
          value={
            studentData?.end_date !== "0000-00-00"
              ? moment(studentData?.end_date).format("DD-MMM-YYYY")
              : "---"
          }
          loading={loading}
        />
        <RowData
          title='Class type'
          value={studentData?.classType ? studentData?.classType : "---"}
          loading={loading}
        />
        <RowData
          title='Class'
          value={
            studentData?.course
              ? `${studentData?.course?.type} (${studentData?.course?.day}) ${studentData?.course?.duration}, ${studentData?.course?.price}USD/${studentData?.course?.per}`
              : "---"
          }
          loading={loading}
        />
        <RowData
          title='Class (Optional)'
          value={studentData?.classoptional || "---"}
          loading={loading}
        />
        <RowData
          title='Instructor'
          value={studentData?.instructor?.name || "---"}
          loading={loading}
        />
        <RowData title='Remark' value={studentData?.remark || "---"} loading={loading} />
      </Grid>

      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
      <Grid container columnSpacing={2}>
        <RowData
          title={`${studentData?.dad_mum}'s name`}
          value={studentData?.parentname}
          loading={loading}
        />
        <RowData title='Address' value={studentData?.address} loading={loading} />
        <RowData title='Contact number' value={studentData?.contactnumber} loading={loading} />
        <RowData title='Emergency contact' value={studentData?.emergcontact} loading={loading} />
        <RowData title='Nationality' value={studentData?.nationality} loading={loading} />
        <RowData
          title='School / Workplace'
          value={studentData?.school_workplace}
          loading={loading}
        />
        <RowData
          title='Relationship'
          value={studentData?.relationship || "---"}
          loading={loading}
        />
        <RowData
          title='Facebook url'
          value={studentData?.facebook_url || "---"}
          loading={loading}
        />
        <RowData title='Telegram' value={studentData?.telegram || "---"} loading={loading} />
      </Grid>

      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
      <Grid container columnSpacing={2}>
        <RowData title='Ever trained swimming?' value={studentData?.havetrain} loading={loading} />
        <RowData title='Heath info' value={studentData?.healthinfo?.join(", ")} loading={loading} />
        <RowData title='Ever drown?' value={studentData?.havedrown} loading={loading} />
        <RowData title='Photo permission' value={studentData?.permission} loading={loading} />
        <RowData title='Found us' value={studentData?.findus?.join(", ")} loading={loading} />
      </Grid>
    </>
  );
}

export default StudentDetail;
