import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import { useRequest } from "ahooks";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { CgAdd } from "react-icons/cg";
import { GrSchedules } from "react-icons/gr";
import { useRecoilValue } from "recoil";
import { CreateSchedule } from "../../../common";
import { CustomTable } from "../../../components";
import { Student as StudentDB } from "../../../hooks/model";
import { StudentProfile as StudentProfileRecoil } from "../../../recoils";
import { addNumberOrderToArray } from "../../../utils/function";

import "./Static.scss";
import { ScheduleCol } from "./table/scheduleCol";

function Schedule({ studentId }) {
  const [openCreate, setOpenCreate] = useState(false);
  const [formType, setFormType] = useState("create");
  const [selectData, setSelectData] = useState();
  const studentProfileState = useRecoilValue(StudentProfileRecoil.studentProfileState);

  const studentIdObj = {
    id: studentProfileState?.student_id,
    label: `(SSC${studentProfileState?.id}) ${studentProfileState?.st_name}`,
    startDate: studentProfileState?.start_date,
    endDate: studentProfileState?.end_date,
    locationId: studentProfileState?.location_id,
    permission: studentProfileState?.permission,
    instructorId: studentProfileState?.inst_id,
    course_id: studentProfileState?.course_id,
    classType: studentProfileState?.classType,
    className: studentProfileState?.course?.type || "",
  };

  const { loading, data, refresh } = useRequest(StudentDB.getSchedule, {
    defaultParams: [{ studentId }],
    pollingInterval: 30000,
    cacheKey: `student-schedule-${studentId}`,
  });

  const handleUpdate = (record) => {
    setSelectData(record);
    setFormType("update");
    setOpenCreate(true);
  };

  const AddSchedule = useMemo(() => {
    return (
      <AccordionSummary
        expandIcon={
          <IconButton
            onClick={() => {
              setFormType("create");
              setOpenCreate(true);
            }}
            aria-label='edit'
            color='success'
            size='default'
          >
            <CgAdd />
          </IconButton>
        }
        aria-controls='panel1a-content'
        id='panel1a-header'
      />
    );
  }, []);

  return (
    <>
      <CreateSchedule
        open={openCreate}
        setOpen={setOpenCreate}
        fetchData={refresh}
        studentId={studentIdObj}
        type={formType}
        selectData={selectData}
      />
      <Box className='student-profile-schedule' style={{ background: "#ffffff" }}>
        <Accordion square expanded>
          <AccordionSummary
            // expandIcon={<MdOutlineExpandMore size={25} />}
            expandIcon={AddSchedule}
            aria-controls='panel1a-content'
            id='panel1a-header'
            // onClick={() => setExpand(!expand)}
          >
            <Typography className='student-profile-schedule-title' variant='h6'>
              <GrSchedules size={15} />
              Schedule
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CustomTable
              className='student-profile-schedule-table'
              columns={ScheduleCol({ handleUpdate })}
              dataSource={addNumberOrderToArray({ array: data?.data }) || []}
              noHeaders
              skeletonCount={5}
              isLoading={loading}
              rowKeys={(record) => record?.sd_id}
              rowClassNames={(record) => {
                if (record?.createdAt < moment().format("YYYY-MM-DD")) return "row-past";

                return "";
              }}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
}

export default Schedule;
