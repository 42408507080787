import { Chip } from "@mui/material";
import React from "react";

function ChipText({ isError, text, textBold, size }) {
  return (
    <Chip
      size={!size ? "small" : size}
      style={{ fontWeight: textBold ? "bold" : "normal" }}
      label={text}
      color={!isError ? "success" : "error"}
    />
  );
}

export default ChipText;
