import { Avatar, Badge, Box, Divider, Grid, Stack } from "@mui/material"; // Button,Fab,Typography
import React, { useEffect } from "react";
// import { FaBars } from "react-icons/fa";
import { Sling as Hamburger } from "hamburger-react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useLocation } from "react-router-dom";
import { HiOutlineLogout } from "react-icons/hi";
import { BiUserCircle } from "react-icons/bi";
import { LogoutRecoil, User as UserRecoil, SideBarState } from "../../recoils";
// import {
//   SantaWave,
//   ChristmasMusic,
//   JingleBellMusic,
//   WeWishYouSong,
//   BoxChat,
// } from "../../assets/xmas";
// style
import "./Header.scss";
import ControlMenu from "../controlmenu/ControlMenu";
import { keyMenu } from "../../utils/function";

const menuProps = {
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};

// const musics = {
//   1: ChristmasMusic,
//   2: JingleBellMusic,
//   3: WeWishYouSong,
// };

// const getMusic = (id) => {
//   if (id === 3) return 1;
//   return id + 1;
// };

// const randomId = () => {
//   const rndInt = Math.floor(Math.random() * (3 - 1 + 1)) + 1;
//   return rndInt;
// };

function Header() {
  const auth = useRecoilValue(UserRecoil.userState);
  const setLogoutState = useSetRecoilState(LogoutRecoil.logoutState);
  const [{ toggle }, setSideBarState] = useRecoilState(SideBarState.sideBarState);
  // new year
  // const [openNewYear, setOpenNewYear] = useState(false);
  // const [isOpenOnce, setIsOpenOnce] = useRecoilState(isOpenOnceAtom);
  // xmas
  // const [isClicked, setIsClicked] = useRecoilState(isClickedSanta);
  // const [santaClass, setSantaClass] = useState("");
  // const [musicId, setMusicId] = useState(randomId());
  // const [showMessage, setShowMessage] = useState(false);
  // const [play, { stop }] = useSound(ChristmasMusic, {
  //   interrupt: true,
  // });
  // const [play2, { stop: stop2 }] = useSound(JingleBellMusic, {
  //   interrupt: true,
  // });
  // const [play3, { stop: stop3 }] = useSound(WeWishYouSong, {
  //   interrupt: true,
  // });

  const location = useLocation();

  useEffect(() => {
    // setTimeout(() => setSantaClass("active-animate"), 5000);
    // setTimeout(() => {
    //   if (!isClicked) {
    //     setShowMessage(true);
    //   }
    // }, 7000);
  }, []);

  const menuItems = [
    {
      id: "1",
      title: <b>Logged as: {auth?.username?.toUpperCase()}</b>,
      icon: <BiUserCircle size={20} />,
      action: () => {},
      isDividerBottom: true,
    },
    {
      id: "2",
      title: "Logout",
      icon: <HiOutlineLogout size={20} color='red' />,
      action: () => setLogoutState({ isOpen: true }),
    },
  ];

  const handleToggleSidebar = () => {
    setSideBarState((prev) => ({
      ...prev,
      toggle: !prev.toggle,
    }));
  };

  // const handlePlayMusic = () => {
  //   setIsClicked(true);
  //   setShowMessage(false);
  //   setMusicId(getMusic(musicId));

  //   if (musicId === 1) {
  //     stop2();
  //     stop3();
  //     play();
  //   } else if (musicId === 2) {
  //     stop();
  //     stop3();
  //     play2();
  //   } else if (musicId === 3) {
  //     stop();
  //     stop2();
  //     play3();
  //   }
  // };

  // if is invoice view
  if (
    keyMenu(location?.pathname) === "view-invoice" ||
    keyMenu(location?.pathname) === "staff-card" ||
    keyMenu(location?.pathname) === "certificate" ||
    keyMenu(location?.pathname) === "student-card"
  )
    return null;

  return (
    <Box className='ssc_header'>
      {/* <ModalFirework open={openNewYear} setOpen={setOpenNewYear} /> */}
      {/* <Popover
        isOpen={showMessage}
        positions={["top", "right"]} // preferred positions by priority
        content={
          <div
            style={{
              padding: "5px 25px",
              position: "relative",
            }}
          >
            <span style={{ zIndex: 20, position: "relative" }}>Click Me</span>
            <img
              src={BoxChat}
              width={120}
              alt=''
              style={{ position: "absolute", left: 0, top: 0, zIndex: 10 }}
            />
          </div>
        }
      >
        <button type='button' onClick={handlePlayMusic} className={`ssc-santa ${santaClass}`}>
          <img src={SantaWave} width={75} alt='' />
        </button>
      </Popover> */}
      <Box className='ssc_header_content'>
        <div
          className={`btn-toggle ${toggle ? "active" : ""}`}
          onClick={() => handleToggleSidebar(!toggle)}
          onKeyDown={() => {}}
          role='button'
          tabIndex={0}
        >
          <Hamburger
            toggled={toggle}
            size={20}
            direction='right'
            // toggle={handleToggleSidebar}
            duration={1}
            // rounded
          />
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Stack direction='row' justifyContent='flex-end' alignItems='center' gap={2}>
              <ControlMenu
                menuItems={menuItems}
                menuProps={menuProps}
                menuIcon={
                  <div className='avatar-container'>
                    <Avatar sx={{ width: 53, height: 53 }}>
                      {auth?.username?.split("")[0]?.toUpperCase() || "U"}
                    </Avatar>
                    <Badge
                      className='badge-active'
                      variant='dot'
                      color='secondary'
                      badgeContent=' '
                    />
                  </div>
                }
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Divider className='ssc_header_divider' />
    </Box>
  );
}

export default Header;
