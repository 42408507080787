import { useRequest } from "ahooks";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { PuffLoader } from "react-spinners";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { CertificateModel } from "../../hooks/model";
import "./Static.scss";
import { BadgeCertificateSSC, CertificateSSC, FrameCertificateSSC } from "../../assets";
import { complete_lesson_options } from "../../constants";
import { getImageUrl } from "../../utils/getUrl";

function Certificate() {
  const { id } = useParams();
  const printRef = useRef();
  const [certificateData, setCertificateDate] = useState(null);
  // please change to create user
  const { loading, run } = useRequest(CertificateModel.getOne, {
    manual: true,
    onSuccess: (data) => {
      if (data?.status) {
        setCertificateDate(data?.data);
      }
    },
  });

  useEffect(() => {
    if (id) {
      run({
        certificateId: id,
      });
    }
  }, [id]);

  useEffect(() => {
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
    return () => {
      document.removeEventListener("contextmenu");
    };
  }, []);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `${certificateData?.st_name}-Certificate`,
    onAfterPrint: () => {
      window.close();
    },
    pageStyle: `
    @page {
      size: a4 landscape;
      margin: 0px;
    }
    `,
  });

  useEffect(() => {
    if (certificateData) {
      handlePrint();
    }
  }, [certificateData, handlePrint]);

  const removeNameKH = () => {
    const studentName = certificateData?.st_name || "";
    const splitName = studentName?.split("-");

    return splitName[0];
  };

  // filter if have lesson
  const filterLesson = complete_lesson_options?.filter((load) =>
    certificateData?.completed_lesson?.find((e) => e?.toLowerCase() === load.value.toLowerCase())
  );

  if (loading)
    return (
      <div className='loading-container'>
        <PuffLoader color='#FFFFFF' />
      </div>
    );

  if (!certificateData)
    return (
      <div className='loading-container' style={{ color: "#FFFFFF" }}>
        <div style={{ textAlign: "center" }}>
          <h3>Something wrong!</h3>Please make sure you generate certificate.
        </div>
      </div>
    );

  return (
    <div className='ssc_certificate_container'>
      <div style={{ display: "none" }}>
        <div className='print-certificate' ref={printRef}>
          <img draggable={false} src={CertificateSSC} className='ssc-certificate-image' alt='' />
          <div className='student-name'>
            <h2>{removeNameKH()}</h2>
          </div>

          {/* student image */}
          <div className='ssc-student-img-box'>
            <img src={FrameCertificateSSC} draggable={false} className='ssc-frame-image' alt='' />
            <div className='ssc-image-container'>
              <img
                draggable={false}
                src={getImageUrl(certificateData?.profile_img)}
                className='ssc-student-image'
                alt=''
              />
            </div>
          </div>

          <div className='ssc-badge'>
            <img src={BadgeCertificateSSC} draggable={false} className='ssc-bage-image' alt='' />
            <a className='ssc-badge-count' style={{ color: "white" }}>
              {filterLesson.length}
            </a>
          </div>

          <ul className='student-lesson'>
            {filterLesson?.map((load, index) => {
              return (
                <li key={load.label} className='lesson-list'>
                  <div style={{ width: 20 }}>{index + 1}.</div>
                  <div>{load?.label}</div>
                </li>
              );
            })}
          </ul>

          <div className='student-no-date'>
            <div>
              No: <span className='student-no'>{certificateData?.ssc_id}</span>
            </div>
            <div>Date: {moment(certificateData?.created_at).format("DD-MMM-YYYY")}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Certificate;
