import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const notificationState = atom({
  key: "notificationStateKey",
  default: {
    new_student_total: 0,
    new_feedback_total: 0,
  },
  effects_UNSTABLE: [persistAtom],
});
