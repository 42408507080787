import Init from "./init";
import { randomFn } from "./randomFn";

class Student {
  static initPath = (path) => {
    return `/api/students${path}`;
  };

  static getByPage = async ({
    page,
    pageSize,
    keyword,
    start_date,
    end_date,
    payment,
    location_id,
    st_status,
  }) => {
    const data = { page, pageSize, keyword, start_date, end_date, location_id, st_status, payment };
    const res = await Init.fnInit(this.initPath("/getPaginator"), data);

    // wait...
    await randomFn();

    return res;
  };

  static getAll = async () => {
    const res = await Init.fnInit(this.initPath("/getAll"));

    // wait...
    await randomFn();

    return res;
  };

  static getNew = async ({ page, pageSize, keyword }) => {
    const data = { page, pageSize, keyword };
    const res = await Init.fnInit(this.initPath("/getNew"), data);

    // wait...
    await randomFn();

    return res;
  };

  static getTotalNew = async () => {
    const res = await Init.fnInit(this.initPath("/getTotalNew"));

    return res;
  };

  static getOne = async ({ studentId }) => {
    const data = { studentId };
    const res = await Init.fnInit(this.initPath("/getOne"), data);

    // wait...
    await randomFn();

    return res;
  };

  static getPayment = async ({ studentId }) => {
    const data = { studentId };
    const res = await Init.fnInit(this.initPath("/getPayment"), data);

    return res;
  };

  static getSchedule = async ({ studentId }) => {
    const data = { studentId };
    const res = await Init.fnInit(this.initPath("/getSchedule"), data);

    return res;
  };

  static add = async (data) => {
    const res = await Init.fnInit(this.initPath("/create"), data);

    return res;
  };

  static update = async (data) => {
    const res = await Init.fnInit(this.initPath("/update"), data);

    return res;
  };

  static updateRemark = async ({ studentId, remark }) => {
    const data = { studentId, remark };
    const res = await Init.fnInit(this.initPath("/updateRemark"), data);

    return res;
  };

  static updateInstructorComment = async ({ studentId, instructor_comment }) => {
    const data = { studentId, instructor_comment };
    const res = await Init.fnInit(this.initPath("/updateInstructorComment"), data);

    return res;
  };

  static updateProfileImage = async ({ studentId, profile_file, old_image }) => {
    const data = { studentId, old_image };
    const file = { fileName: "profile_image", fileObject: profile_file };
    const res = await Init.fnInitWithFile(this.initPath("/updateProfileImage"), data, file);

    return res;
  };

  static updateCompleteLesson = async ({ lesson, studentId }) => {
    const data = { lesson, studentId };
    const res = await Init.fnInit(this.initPath("/updateCompleteLesson"), data);

    return res;
  };

  static updateSelfHelp = async ({ studentId, selfHelp }) => {
    const data = { studentId, selfHelp };
    const res = await Init.fnInit(this.initPath("/updateSelfHelp"), data);

    return res;
  };

  static confirm = async ({ studentId }) => {
    const data = { studentId };
    const res = await Init.fnInit(this.initPath("/confirm"), data);

    return res;
  };

  static delete = async ({ studentId }) => {
    const data = { studentId };
    const res = await Init.fnInit(this.initPath("/delete"), data);

    return res;
  };

  static deleteNew = async ({ studentId }) => {
    const data = { studentId };
    const res = await Init.fnInit(this.initPath("/deleteNew"), data);

    return res;
  };
}

export default Student;
