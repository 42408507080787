import { Grid, Skeleton, Typography } from "@mui/material";
import React from "react";

function RowData({ title, value, loading }) {
  return (
    <>
      <Grid item xs={4} sm={4} md={4} lg={4}>
        {!loading ? (
          <Typography fontWeight='bold' variant='body1' gutterBottom>
            {title}
          </Typography>
        ) : (
          <Skeleton animation='wave' />
        )}
      </Grid>
      <Grid item xs={1} sm={1} md={1} lg={1}>
        :
      </Grid>
      <Grid item xs={7} sm={7} md={7} lg={7}>
        {!loading ? value : <Skeleton animation='wave' />}
      </Grid>
    </>
  );
}

export default RowData;
