import React, { useMemo } from "react";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SubMenu,
} from "react-pro-sidebar";
import { RiDashboardFill, RiFeedbackFill, RiLogoutCircleRFill } from "react-icons/ri"; // RiUserSettingsFill,// RiDashboardFill,
import { GiTeacher } from "react-icons/gi";
import { AiFillSchedule } from "react-icons/ai";
import { HiUsers } from "react-icons/hi";
import { Badge } from "@mui/material";
import { FaFileInvoiceDollar, FaSwimmer } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { ImLocation2 } from "react-icons/im";
import { SiGoogleclassroom, SiGooglesheets } from "react-icons/si";
import { TbPool, TbReportAnalytics } from "react-icons/tb";
import { MdDoNotTouch, MdNewReleases } from "react-icons/md";
import { useRequest } from "ahooks";
import { useRecoilState, useRecoilValue } from "recoil";
// style
import "./SideBar.scss";
import { keyMenu, openNewTab } from "../../utils/function";
import Logout from "../logout/Logout";
import Logo from "../../assets/logo/ssc-logo.png";
import { Student as StudentDB, Feedback as FeedbackDB } from "../../hooks/model";
import { pushWebNotification } from "../../utils/webNotification";
import { LogoutRecoil, Notification, SideBarState, User as UserAuth } from "../../recoils";
import { encryptString } from "../../utils/encryption";

function SideBar() {
  const auth = useRecoilValue(UserAuth.userState);
  const [logoutState, setLogoutState] = useRecoilState(LogoutRecoil.logoutState);
  const [{ toggle }, setSideBarState] = useRecoilState(SideBarState.sideBarState);
  const [{ new_feedback_total, new_student_total }, setNoticationState] = useRecoilState(
    Notification.notificationState
  );

  const encryptedAuth = encryptString(JSON.stringify({ ...auth }));
  const adminTimesheetUrl = new URLSearchParams({ user_auth: encryptedAuth });

  const navigate = useNavigate();
  const urlPath = useLocation().pathname;

  const setRecoilStudent = (total) => {
    setNoticationState({
      new_feedback_total,
      new_student_total: total,
    });
  };

  const setRecoilFeedback = (total) => {
    setNoticationState({
      new_student_total,
      new_feedback_total: total,
    });
  };

  const { data } = useRequest(StudentDB.getTotalNew, {
    pollingInterval: 20000,
    onSuccess: (res) => {
      if (res?.status) {
        const totalInt = parseInt(res?.total || 0, 10);
        if (totalInt > new_student_total && totalInt > 0) {
          pushWebNotification({
            title: `(+${res?.total}) New Student`,
            subtitle: `Please check your system (+${res?.total})`,
            message: `Please check your system (+${res?.total})`,
          });

          setRecoilStudent(totalInt);
        }
        if (new_student_total === 0) {
          setRecoilStudent(totalInt);
        }
        if (totalInt < new_student_total) {
          setRecoilStudent(totalInt);
        }
      }
    },
  });
  const { data: feedbackData } = useRequest(FeedbackDB.getTotalNew, {
    pollingInterval: 20000,
    onSuccess: (res) => {
      if (res?.status) {
        const totalInt = parseInt(res?.total || 0, 10);
        if (totalInt > new_feedback_total && totalInt > 0) {
          pushWebNotification({
            title: `(+${res?.total}) New Feedback`,
            subtitle: `Please check your system +${res?.total}`,
            message: `Please check your system +${res?.total}`,
          });

          setRecoilFeedback(totalInt);
        }
        if (new_feedback_total === 0) {
          setRecoilFeedback(totalInt);
        }
        if (totalInt < new_feedback_total) {
          setRecoilFeedback(totalInt);
        }
      }
    },
  });

  const totalNewStudent = useMemo(() => data?.total, [data?.total]);
  const totalNewFeedback = useMemo(() => feedbackData?.total, [feedbackData?.total]);

  const menuList = [
    {
      id: "1",
      title: "Dashboard",
      path: "",
      icon: <RiDashboardFill />,
    },
    {
      id: "2",
      title: "Schedule",
      path: "schedule",
      icon: <AiFillSchedule />,
    },
    {
      id: "3",
      title: "New",
      path: "new",
      icon: <MdNewReleases />,
      badgeNumber: parseInt(totalNewStudent || 0, 10),
    },
    {
      id: "4",
      title: "Swimmer",
      path: "swimmer",
      icon: <FaSwimmer />,
    },
    {
      id: "5",
      title: "Deactivated",
      path: "deactivated",
      icon: <MdDoNotTouch />,
    },
    {
      id: "6",
      title: "Invoice List",
      path: "invoice",
      icon: <FaFileInvoiceDollar />,
    },
    {
      id: "7",
      title: "Feedback",
      // path: "feedback",
      icon: <RiFeedbackFill />,
      children: [
        {
          id: "1",
          title: "New",
          path: "new-feedback",
          icon: "",
          badgeNumber: parseInt(totalNewFeedback || 0, 10),
        },
        {
          id: "2",
          title: "Feedback",
          path: "feedback",
          icon: "",
        },
        {
          id: "3",
          title: "Service",
          path: "feedback-service",
          icon: "",
        },
        {
          id: "4",
          title: "Instructor",
          path: "feedback-instructor",
          icon: "",
        },
        {
          id: "5",
          title: "Pool",
          path: "feedback-pool",
          icon: "",
        },
        {
          id: "6",
          title: "Fee",
          path: "feedback-fee",
          icon: "",
        },
      ],
    },
    {
      id: "8",
      title: "Timesheet",
      path: "timesheet",
      icon: <SiGooglesheets />,
      actionClick: () => {
        openNewTab({
          url: `https://admin-timesheet.survivalswimclub.com/#/?${adminTimesheetUrl}`,
          // url: `http://localhost:3000/#/?${adminTimesheetUrl}`,
        });
      },
    },
    {
      id: "9",
      title: "Instructor",
      path: "instructor",
      icon: <GiTeacher />,
    },
    {
      id: "10",
      title: "User",
      path: "user",
      icon: <HiUsers />,
    },
    {
      id: "11",
      title: "Course",
      path: "course",
      icon: <SiGoogleclassroom />,
    },
    {
      id: "12",
      title: "Pool",
      path: "pool",
      icon: <TbPool />,
    },
    {
      id: "13",
      title: "Location",
      path: "location",
      icon: <ImLocation2 />,
    },
    {
      id: "14",
      title: "Swimmer Report",
      path: "swimmer-report",
      icon: <TbReportAnalytics />,
    },
  ];

  const handleToggleSidebar = () => {
    setSideBarState((prev) => ({
      ...prev,
      toggle: !prev.toggle,
    }));
  };

  const handleNavigate = (e) => {
    handleToggleSidebar();
    navigate(e);
  };

  // remove user if not admin
  if (auth?.post !== "admin") {
    // index 9 is User
    menuList.splice(9, 1);
  }

  if (auth?.post === "staff") {
    // index 9 is User
    menuList.splice(8, 1);
    menuList.splice(7, 1);
  }

  // if is invoice or certificate view
  if (
    keyMenu(urlPath) === "view-invoice" ||
    keyMenu(urlPath) === "certificate" ||
    keyMenu(urlPath) === "staff-card" ||
    keyMenu(urlPath) === "student-card"
  )
    return null;

  return (
    <>
      <Logout setOpen={(e) => setLogoutState({ isOpen: e })} open={logoutState?.isOpen} />
      <ProSidebar
        breakPoint='md'
        // collapsed={true}
        style={{ height: "none" }}
        toggled={toggle}
        onToggle={handleToggleSidebar}
        className='custome-sidebar'
      >
        <SidebarHeader>
          <div style={{ fontSize: 30, textAlign: "center" }}>
            <img src={Logo} style={{ width: "95%", margin: "20px 0px" }} alt='Logo' />
            {/* LOGO */}
          </div>
        </SidebarHeader>

        <SidebarContent>
          <Menu iconShape='circle'>
            {menuList?.map((menu) => {
              // if (menu.id === "8")
              //   return (
              //     (auth?.post === "admin" || auth?.post === "user") && (
              //       <MenuItem
              //         key={menu?.id}
              //         onClick={() => {
              //           if (menu?.actionClick) {
              //             menu.actionClick();
              //             return;
              //           }
              //           handleNavigate(`/${menu?.path}`);
              //         }}
              //         className={keyMenu(urlPath) === menu?.path ? "active_tab" : ""}
              //         icon={menu?.icon}
              //       >
              //         {menu?.title}
              //       </MenuItem>
              //     )
              //   );

              return menu?.children ? (
                <SubMenu
                  title={menu.title}
                  key={menu?.id}
                  icon={menu?.icon}
                  className={
                    menu?.children?.map((e) => e.path).includes(keyMenu(urlPath))
                      ? "active_tab"
                      : ""
                  }
                >
                  {menu?.children.map((load) => (
                    <MenuItem
                      key={load?.id}
                      onClick={() => handleNavigate(`/${load?.path}`)}
                      className={keyMenu(urlPath) === load?.path ? "active_tab" : ""}
                      suffix={
                        <Badge
                          invisible={load?.badgeNumber <= 0}
                          badgeContent={load?.badgeNumber ? load?.badgeNumber + 1 : null}
                          color='secondary'
                          className='new-student-badge'
                          style={{ right: 30 }}
                          max={load?.badgeNumber || 0}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        />
                      }
                    >
                      {load?.title}
                    </MenuItem>
                  ))}
                </SubMenu>
              ) : (
                <MenuItem
                  key={menu?.id}
                  onClick={() => {
                    if (menu?.actionClick) {
                      menu.actionClick();
                      return;
                    }
                    handleNavigate(`/${menu?.path}`);
                  }}
                  className={keyMenu(urlPath) === menu?.path ? "active_tab" : ""}
                  icon={menu?.icon}
                  suffix={
                    <Badge
                      invisible={menu?.badgeNumber <= 0}
                      badgeContent={menu?.badgeNumber ? menu?.badgeNumber + 1 : null}
                      color='secondary'
                      className='new-student-badge'
                      // sx={{ color: "yellow", background: "blue" }}
                      style={{ right: 30 }}
                      max={menu?.badgeNumber || 0}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    />
                  }
                >
                  {menu?.title}
                </MenuItem>
              );
            })}
          </Menu>
        </SidebarContent>

        <SidebarFooter className='sidebar_footer'>
          <Menu iconShape='circle'>
            <MenuItem
              onClick={() => setLogoutState({ isOpen: true })}
              className='logout_footer'
              icon={<RiLogoutCircleRFill />}
            >
              Logout
            </MenuItem>
          </Menu>
        </SidebarFooter>
      </ProSidebar>
    </>
  );
}

export default SideBar;
