import { Autocomplete, TextField } from "@mui/material";
import { useRequest } from "ahooks";
import { Controller } from "react-hook-form";
import { Course as CourseDB } from "../../hooks/model";

// import "./Static.scss";

function SelectCourse({ control, name, error, helperText, required, label }) {
  const { data, loading } = useRequest(CourseDB.getAll);

  const dataSelect = !data
    ? []
    : data?.data?.map((e) => ({
        id: e?.id,
        label: `${e?.type} (${e?.day}) ${e?.duration}, ${e?.price}USD/${e?.per}`,
      }));

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          disablePortal
          id='combo-box-pool'
          value={value}
          size='small'
          options={dataSelect}
          onChange={(option, e) => {
            onChange(e);
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.label}
              </li>
            );
          }}
          isOptionEqualToValue={(option, e) => option?.id === e?.id}
          getOptionLabel={(option) => `${option.label}`}
          style={{ width: "100%" }}
          loading={loading}
          renderInput={(params) => (
            <TextField
              error={error}
              helperText={helperText}
              required={required}
              label={label || "Class"}
              {...params}
            />
          )}
        />
      )}
    />
  );
}

export default SelectCourse;
