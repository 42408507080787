import { Grid, IconButton, Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useRequest } from "ahooks";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IoCloseCircle } from "react-icons/io5";
import * as yup from "yup";

import { toast } from "react-toastify";
import { MdPool } from "react-icons/md";
import { Feedback as FeedbackDB } from "../../../hooks/model";
import { PrimaryModal, SelectInstructor } from "../../../components";

const initialState = {
  campaign: "",
  instructor_id: null,
};

const schema = yup
  .object({
    campaign: yup.string(),
    instructor_id: yup.object().nullable(),
  })
  .required();

function Create({ setOpen, open, fetchData, selectData, type }) {
  // inizialize form
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { ...initialState },
  });

  useEffect(() => {
    reset();
    function initFunction() {
      setValue("campaign", selectData.campaign);
      setValue("instructor_id", {
        id: selectData.instructor.id,
        label: selectData.instructor.name,
      });
    }

    if (type === "update") {
      initFunction();
    } else {
      reset();
    }
  }, [selectData, setValue, type, reset]);

  const reqSuccess = (data) => {
    if (data) {
      fetchData();
      toast.success(data?.message);
      reset();
      setOpen(false);
    }
  };

  // please change to create user
  const { loading: updateLoading, run: onUpdate } = useRequest(FeedbackDB.update, {
    manual: true,
    onSuccess: (data) => reqSuccess(data),
  });

  const onSubmit = (data) => {
    if (type === "update") {
      onUpdate({
        ...selectData,
        ...data,
        instructor_id: data?.instructor_id?.id || 0,
      });
    }
  };

  return (
    <PrimaryModal
      open={open}
      setOpen={setOpen}
      title={
        <Grid container spacing={2}>
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <Typography variant='h5' gutterBottom component='div'>
              <MdPool /> {type === "update" ? "Update Feedback" : "Add Feedback"}
            </Typography>
            <Typography variant='subtitle2' gutterBottom component='div'>
              {type === "update"
                ? `Feedback ID: ${selectData.f_id}`
                : "Complete required field to add."}
            </Typography>
          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2}>
            <Stack direction='row' justifyContent='flex-end' alignItems='center'>
              <IconButton color='error' onClick={() => setOpen(false)}>
                <IoCloseCircle />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            error={!!errors?.campaign}
            helperText={<span>{errors?.campaign?.message}</span>}
            style={{ width: "100%" }}
            required
            size='small'
            label='Campaign'
            {...register("campaign")}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <SelectInstructor
            name='instructor_id'
            control={control}
            required
            error={!!errors?.instructor_id}
            helperText={<span>{errors?.instructor_id?.message}</span>}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <LoadingButton
            style={{ width: "100%" }}
            className='ssc_text_field'
            size='small'
            variant='contained'
            disableElevation
            loading={updateLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {type === "update" ? "Update" : "Create"}
          </LoadingButton>
        </Grid>
      </Grid>
    </PrimaryModal>
  );
}

export default Create;
