import { selector } from "recoil";
import { studentPaymentState } from "./atom";

export const studentPaymentSelector = selector({
  key: "studentPaymentSelector",
  get: ({ get }) => {
    const { invoices } = get(studentPaymentState);
    const paidInvoices = invoices?.filter((e) => e?.status === "paid");
    let latestInvoice = null;

    if (paidInvoices?.length > 0) {
      latestInvoice = paidInvoices[paidInvoices?.length - 1];
    }

    return { invoices, latestInvoice };
  },
});
