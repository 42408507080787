import { Autocomplete, TextField } from "@mui/material";
import { useRequest } from "ahooks";
import { Controller } from "react-hook-form";
import { getAllStudent } from "../../hooks/db";

import "./Static.scss";

function SelectStudent({
  control,
  name,
  error,
  helperText,
  required,
  disableClearable,
  label,
  disabled,
}) {
  const { data, loading } = useRequest(getAllStudent);

  const dataSelect = !data
    ? []
    : data?.data?.map((e) => ({
        id: e?.id,
        label: `(SSC${e?.id}) ${e?.st_name}`,
        startDate: e?.start_date,
        endDate: e?.end_date,
        locationId: e?.location_id,
        permission: e?.permission,
        instructorId: e?.inst_id,
        course_id: e?.course_id,
        classType: e?.classType,
        className: e?.className,
      }));

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          disablePortal
          id='combo-box-student'
          // className='student-autocomplete'
          value={value}
          onChange={(option, e) => {
            onChange(e);
          }}
          options={dataSelect}
          isOptionEqualToValue={(option, e) => option?.id === e?.id}
          getOptionLabel={(option) => option.label}
          fullWidth
          disableClearable={disableClearable}
          disabled={disabled || false}
          loading={loading}
          size='small'
          renderOption={(props, option) => {
            return (
              <li style={{ fontFamily: "KhmerOSContent" }} {...props} key={option.id}>
                {option.label}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              required={required}
              error={error}
              helperText={helperText}
              placeholder='student'
              {...params}
              label={label !== null && label !== undefined ? label : "Student"}
            />
          )}
        />
      )}
    />
  );
}

export default SelectStudent;
