import Init from "./init";

class CertificateModel {
  static initPath = (path) => {
    return `/api/certificates${path}`;
  };

  static getByPage = async ({ page, pageSize, keyword, location_id }) => {
    const data = { page, pageSize, keyword, location_id };
    const res = await Init.fnInit(this.initPath("/getPaginator"), data);

    return res;
  };

  static getOne = async ({ certificateId }) => {
    const data = { certificateId };
    const res = await Init.fnInit(this.initPath("/getOne"), data);

    return res;
  };

  static add = async ({
    user_id,
    student_id,
    completed_lesson,
    instructor_comment,
    self_help,
    instructor_id,
  }) => {
    const data = {
      user_id,
      student_id,
      completed_lesson,
      instructor_comment,
      self_help,
      instructor_id,
    };
    // const rndInt = Math.floor(Math.random() * (40 - 20 + 1)) + 20;
    // await new Promise((resolve) => setTimeout(resolve, rndInt * 1000));

    const res = await Init.fnInit(this.initPath("/create"), data);

    return res;
  };

  static update = async ({
    id,
    user_id,
    student_id,
    completed_lesson,
    instructor_comment,
    self_help,
    instructor_id,
  }) => {
    const data = {
      id,
      user_id,
      student_id,
      completed_lesson,
      instructor_comment,
      self_help,
      instructor_id,
    };
    const res = await Init.fnInit(this.initPath("/update"), data);

    return res;
  };

  static delete = async ({ id }) => {
    const data = { id };
    const res = await Init.fnInit(this.initPath("/delete"), data);

    return res;
  };
}

export default CertificateModel;
