import { ToastContainer } from "react-toastify";
import { Notifications } from "react-push-notification";
import { RecoilURLSyncJSON } from "recoil-sync";
import { RecoilRoot } from "recoil";
import RouteConfig from "./RouteConfig";

import "./styles/theme.scss";

function App() {
  return (
    <RecoilRoot>
      <RecoilURLSyncJSON storeKey='json-query' location={{ part: "queryParams" }}>
        <RouteConfig />
        <ToastContainer />
        <Notifications />
      </RecoilURLSyncJSON>
    </RecoilRoot>
  );
}

export default App;
