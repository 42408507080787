import { IconButton, Stack } from "@mui/material";
import moment from "moment";
import { MdEditCalendar } from "react-icons/md";

export function ScheduleCol({ handleUpdate }) {
  const columns = [
    {
      key: "no",
      title: "N°",
      width: 50,
      align: "center",
    },
    {
      key: "createdAt",
      width: 100,
      render: (record) => {
        return <div>{moment(record?.createdAt).format("DD-MMM-YYYY")}</div>;
      },
    },
    {
      key: "time",
      width: 100,
      // render: (record) => {
      //   return <div>{moment(record?.created).format("DD-MMM-YYYY")}</div>;
      // },
    },
    {
      key: "action",
      title: "Action",
      fixed: true,
      width: 30,
      render: (record) => {
        return (
          <Stack>
            <IconButton
              aria-label='edit'
              color='success'
              size='small'
              disabled={record?.createdAt < moment().format("YYYY-MM-DD")}
              onClick={() => handleUpdate(record)}
            >
              <MdEditCalendar />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  return columns;
}
