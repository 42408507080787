import { Grid, IconButton, Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { BsCalendarPlus } from "react-icons/bs";
import { useRequest } from "ahooks";
import React, { memo, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IoCloseCircle } from "react-icons/io5";
import * as yup from "yup";
import { useRecoilValue } from "recoil";

import moment from "moment";
import { toast } from "react-toastify";
import { ScheduleDB } from "../../../hooks/model";
import {
  ControllDatePicker,
  ControlSwitch,
  PrimaryModal,
  SelectInstructor,
  SelectPool,
  SelectStudent,
  SelectTime,
} from "../../../components";

import { StudendPaymentRecoil } from "../../../recoils";

const initialState = {
  invoiceNumber: "",
  studentId: null,
  registerDate: null,
  expireDate: null,
  poolId: null,
  isAllowPhoto: 0,
  instructorId: 0,
  standById: null,
  createdAt: moment(),
  classType: "",
  time: null,
  remark: "",
  postpone: false,
};

const schema = yup
  .object({
    studentId: yup.object().nullable().required("Student is required!"),
    // invoiceNumber: yup.string().required("Invoice ID is required"),
    poolId: yup.object().nullable().required("Pool is required!"),
    createdAt: yup.date().required("Date is required!"),
    time: yup.object().nullable().required("Time is required!"),
    standById: yup.object().nullable().required("Stand by is required!"),
  })
  .required();

function CreateSchedule({ setOpen, open, fetchData, initialDate, studentId, selectData, type }) {
  const isUpdate = type === "update";
  const { latestInvoice } = useRecoilValue(StudendPaymentRecoil.studentPaymentSelector);

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { ...initialState },
  });

  const resSuccess = (res) => {
    if (res?.status) {
      toast.success(res?.message);
      setOpen(false);
      reset();
      fetchData();
    }
  };

  const { loading, run: runCreate } = useRequest(ScheduleDB.add, {
    manual: true,
    onSuccess: (data) => {
      resSuccess(data);
    },
  });

  const { loading: loadingUpdate, run: runUpdate } = useRequest(ScheduleDB.update, {
    manual: true,
    onSuccess: (data) => {
      resSuccess(data);
    },
  });

  useEffect(() => {
    if (!open) {
      reset();
    }
    if (initialDate) {
      setValue("createdAt", initialDate);
    }
    if (studentId) {
      setValue("studentId", studentId);
    }
    if (!isUpdate && latestInvoice) {
      setValue("invoiceNumber", latestInvoice?.invoice_id || "");
    }

    function initData() {
      setValue("time", { label: selectData?.time, value: selectData?.time } || null);
      setValue(
        "standById",
        Number(selectData?.standById) > 0
          ? { label: selectData?.standByName, id: selectData?.standById }
          : null
      );
      setValue("createdAt", moment(selectData?.createdAt) || null);
      setValue("invoiceNumber", selectData?.invoiceNumber || "");
      setValue("remark", selectData?.remark || "");
      setValue("postpone", parseInt(selectData?.postpone || 0, 10) > 0);
      // setValue("remark_postpone", selectData?.remark_postpone || "");

      const poolId = selectData?.pool
        ? { id: selectData?.pool?.p_id, label: selectData?.pool?.poolName }
        : null;

      setValue("poolId", poolId);
    }

    if (type === "update") {
      initData();
    }
  }, [open]);

  const onSubmit = (data) => {
    if (data?.studentId?.classType === "") {
      toast.warning("Warning! student has no class type.");
      return;
    }

    if (data?.studentId?.className === "") {
      toast.warning("Warning! student has no class.");
      return;
    }

    if (type === "update") {
      runUpdate({
        ...data,
        poolId: data?.poolId?.id,
        time: data?.time?.value,
        standById: data?.standById?.id,
        postpone: data?.postpone ? 1 : 0,
        createdAt: moment(data?.createdAt).format("YYYY-MM-DD"),
        scheduleId: selectData?.sd_id,
      });
    } else {
      runCreate({
        ...data,
        studentId: data?.studentId?.id,
        registerDate: moment(data?.studentId?.startDate).format("YYYY-MM-DD"),
        expireDate: moment(data?.studentId?.endDate).format("YYYY-MM-DD"),
        isAllowPhoto: data?.studentId?.permission === "Yes" ? 1 : 0,
        poolId: data?.poolId?.id,
        time: data?.time?.value,
        standById: data?.standById?.id,
        createdAt: moment(data?.createdAt).format("YYYY-MM-DD"),
        course_id: data?.studentId?.course_id,
        classType: data?.studentId?.classType,
        className: data?.studentId?.className,
      });
    }
  };

  return (
    <PrimaryModal
      open={open}
      setOpen={setOpen}
      title={
        <Grid container spacing={2}>
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <Typography variant='h5' gutterBottom component='div'>
              <BsCalendarPlus /> {type === "update" ? "Update Schedule" : "Add Schedule"}
            </Typography>
            <Typography variant='subtitle2' gutterBottom component='div'>
              Complete required field to add.
            </Typography>
          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2}>
            <Stack direction='row' justifyContent='flex-end' alignItems='center'>
              <IconButton color='error' onClick={() => setOpen(false)}>
                <IoCloseCircle />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={2}>
        {!isUpdate ? (
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <SelectStudent
              name='studentId'
              disabled={!!studentId}
              control={control}
              required
              error={!!errors?.studentId}
              helperText={<span>{errors?.studentId?.message}</span>}
            />
          </Grid>
        ) : null}

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            error={!!errors?.invoiceNumber}
            helperText={<span>{errors?.invoiceNumber?.message}</span>}
            style={{ width: "100%" }}
            // required
            size='small'
            disabled
            label='Invoice ID'
            {...register("invoiceNumber")}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <SelectPool
            name='poolId'
            control={control}
            required
            error={!!errors?.poolId}
            helperText={<span>{errors?.poolId?.message}</span>}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <ControllDatePicker
            name='createdAt'
            control={control}
            required
            minDate={moment()}
            error={!!errors?.createdAt}
            helperText={<span>{errors?.createdAt?.message}</span>}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <SelectTime
            name='time'
            control={control}
            required
            error={!!errors?.time}
            helperText={<span>{errors?.time?.message}</span>}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <SelectInstructor
            name='standById'
            label='Stand by'
            control={control}
            required
            error={!!errors?.standById}
            helperText={<span>{errors?.standById?.message}</span>}
          />
        </Grid>

        {isUpdate ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <ControlSwitch
              name='postpone'
              control={control}
              required
              label='Postpone?'
              error={!!errors?.postpone}
              helperText={<span>{errors?.postpone?.message}</span>}
              // color={watch("st_status") ? "success" : "error"}
            />
          </Grid>
        ) : null}

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            multiline
            rows={6}
            placeholder='remark...'
            error={!!errors?.remark}
            helperText={<span>{errors?.remark?.message}</span>}
            style={{ width: "100%" }}
            size='small'
            label={null}
            {...register("remark")}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <LoadingButton
            style={{ width: "100%" }}
            className='ssc_text_field'
            size='small'
            variant='contained'
            disableElevation
            loading={loading || loadingUpdate}
            onClick={handleSubmit(onSubmit)}
          >
            {type === "update" ? "Update" : "Save"}
          </LoadingButton>
        </Grid>
      </Grid>
    </PrimaryModal>
  );
}

export default memo(CreateSchedule);
