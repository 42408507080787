import Init from "./init";

class ScheduleDB {
  static initPath = (path) => {
    return `/api/schedule${path}`;
  };

  static getByPage = async ({ page, pageSize, keyword }) => {
    const data = { page, pageSize, keyword };
    const res = await Init.fnInit("/api/schedule/getPaginator", data);

    return res;
  };

  static getAll = async () => {
    const res = await Init.fnInit("/api/schedule/getAll");

    return res;
  };

  static getByMonth = async ({ date, student_id }) => {
    const data = { date, student_id };
    const res = await Init.fnInit("/api/schedule/getByMonth", data);

    return res;
  };

  static add = async ({
    studentId,
    registerDate,
    expireDate,
    isAllowPhoto,
    poolId,
    time,
    standById,
    createdAt,
    course_id,
    classType,
    className,
    remark,
    invoiceNumber,
  }) => {
    const data = {
      studentId,
      registerDate,
      expireDate,
      isAllowPhoto,
      poolId,
      time,
      standById,
      createdAt,
      course_id,
      classType,
      className,
      remark,
      invoiceNumber,
    };
    const res = await Init.fnInit("/api/schedule/create", data);

    return res;
  };

  static update = async ({
    poolId,
    time,
    standById,
    createdAt,
    remark,
    postpone,
    remark_postpone,
    scheduleId,
  }) => {
    const data = {
      poolId,
      time,
      standById,
      createdAt,
      remark,
      postpone,
      remark_postpone,
      scheduleId,
    };
    const res = await Init.fnInit("/api/schedule/update", data);

    return res;
  };

  static delete = async ({ id }) => {
    const data = { id };
    const res = await Init.fnInit("/api/locations/delete", data);

    return res;
  };
}

export default ScheduleDB;
