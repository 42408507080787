import React from "react";

import "./Static.scss";

function RowAccounting({ title, value }) {
  return (
    <tr className='total'>
      <td />
      <td style={{ width: "70%" }}>{title}</td>
      <td style={{ width: "5%" }}>:</td>
      <td style={{ width: "25%" }}>{value}</td>
    </tr>
  );
}

export default RowAccounting;
