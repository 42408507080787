import { Autocomplete, TextField } from "@mui/material";
import { useRequest } from "ahooks";
import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { getAllPool } from "../../hooks/db";

function SelectPool({ control, name, error, helperText, required, filterData }) {
  const { data, loading, run } = useRequest(getAllPool);

  const filteringData = () => {
    if (filterData?.locationId) {
      return data?.data?.filter((e) => e?.locationId === filterData?.locationId);
    }
    return data?.data;
  };

  const filtered = !data ? [] : filteringData();

  const dataSelect = !data
    ? []
    : filtered?.map((e) => ({
        id: e?.p_id,
        label: e?.poolName,
        locationId: e?.locationId,
      }));

  useEffect(() => {
    run();
  }, [run, filterData]);

  // const getSelectData = (data) =>{
  //     if(filterData?.locationId){
  //         return data?.filter(e => e?.locationId === filterData?.locationId)
  //     }
  //     return data
  // }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          disablePortal
          id='combo-box-pool'
          value={value}
          size='small'
          options={dataSelect}
          onChange={(option, e) => {
            onChange(e);
          }}
          isOptionEqualToValue={(option, e) => option?.id === e?.id}
          getOptionLabel={(option) => option.label}
          style={{ width: "100%" }}
          loading={loading}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.label}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              error={error}
              helperText={helperText}
              required={required}
              {...params}
              label='Pool'
            />
          )}
        />
      )}
    />
  );
}

export default SelectPool;
