import { IconButton, Skeleton, Stack } from "@mui/material";
import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import PageTitle from "../pagetitle/PageTitle";

function BreadCrumb({ pageTitle, isLoading }) {
  const urlPath = useLocation().pathname;
  const pathLength = urlPath?.split("/").filter((e) => e !== "").length;
  const navigate = useNavigate();

  const backButton = () => {
    if (pathLength <= 1) return null;

    return (
      <IconButton onClick={() => navigate(-1)}>
        <AiOutlineArrowLeft />
      </IconButton>
    );
  };

  return (
    <Stack direction='row' alignItems='center' gap={2} width='100%'>
      {!isLoading ? (
        <>
          {backButton()}
          <PageTitle title={pageTitle} />
        </>
      ) : (
        <Skeleton variant='text' width='100%' animation='wave' />
      )}
    </Stack>
  );
}

export default BreadCrumb;
