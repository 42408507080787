import axios from "axios";
import { toast } from "react-toastify";
import { getApi } from "../utils/dev";

export const fetchData = async (path, params) => {
  try {
    const res = await axios.post(`${getApi()}${path}`, params, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });

    if (res?.data?.error) {
      toast.error(res?.data?.error);
      return {
        status: false,
        data: [],
      };
    }

    return {
      status: true,
      ...res?.data,
    };
  } catch (error) {
    toast.error(error?.message);
    return {
      status: false,
      data: [],
    };
  }
};
