import { FormControl, FormControlLabel, FormHelperText, Switch } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

// import "./Static.scss";

function ControlSwitch({ control, name, error, helperText, required, label, ...restProps }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl required={required} fullWidth size='small' error={error}>
          <FormControlLabel
            // labelPlacement='end'
            control={<Switch {...restProps} onChange={onChange} value={value} checked={value} />}
            label={label}
          />
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      )}
    />
  );
}

export default ControlSwitch;
