import { Grid, MenuItem, Select, Tooltip } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useRecoilState, useRecoilValue } from "recoil";
import { toast } from "react-toastify";
import { useRequest } from "ahooks";
import React, { useEffect, useState } from "react";
import { BiEdit } from "react-icons/bi";
import { RowData } from "../../components";
import CompleteLessonCheck from "./CompleteLessonCheck";
import { CertificateModel, Student as StudentDB } from "../../hooks/model";
import { StudentProfile as StudentProfileRecoil, User as UserAuth } from "../../recoils";
import UpdateInstComment from "./modal/UpdateInstComment";
import { openNewTab } from "../../utils/function";
import "./Static.scss";

const yes_no = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];

function SelectAccessment({ studentProfile }) {
  const [selfHelp, setSelfHelp] = useState(null);

  useEffect(() => {
    setSelfHelp(studentProfile?.self_help);
  }, [studentProfile?.self_help]);

  const reqSuccess = (data) => {
    if (data?.status) {
      toast.success(data?.message);
      setSelfHelp(data?.selfHelp);
    }
  };

  // please change to create user
  const { run: onUpdate, loading } = useRequest(StudentDB.updateSelfHelp, {
    manual: true,
    onSuccess: (data) => reqSuccess(data),
  });

  const handleSelect = (e) => {
    setSelfHelp(e.target.value);
    onUpdate({
      selfHelp: e.target.value,
      studentId: studentProfile?.student_id,
    });
  };

  return (
    <Select placeholder='Choose' disabled={loading} onChange={handleSelect} value={selfHelp || ""}>
      {yes_no.map((load) => (
        <MenuItem value={load?.value} key={load?.value}>
          {load?.label}
        </MenuItem>
      ))}
    </Select>
  );
}

function CompleteLesson({ loading }) {
  const auth = useRecoilValue(UserAuth.userState);
  const [openInstComment, setOpenInstComment] = useState(false);
  const [studentProfile, setStudentProfile] = useRecoilState(
    StudentProfileRecoil.studentProfileState
  );

  // please change to create user
  const { run: onCreate, loading: loadingCreate } = useRequest(CertificateModel.add, {
    manual: true,
    onSuccess: (data) => {
      if (data?.status) {
        openNewTab({ url: `/certificate/${data?.id}` });
      }
    },
  });

  const handleCreate = () => {
    onCreate({
      student_id: studentProfile?.student_id,
      instructor_id: studentProfile?.inst_id,
      user_id: auth?.id,
      completed_lesson: studentProfile?.completed_lesson,
      instructor_comment: studentProfile?.instructor_comment,
      self_help: studentProfile?.self_help,
    });
  };

  const isAbleCreate = studentProfile?.completed_lesson?.length > 0;

  return (
    <>
      <UpdateInstComment
        open={openInstComment}
        setOpen={setOpenInstComment}
        setStudentProfile={setStudentProfile}
        studentProfile={studentProfile}
      />
      <Grid container columnSpacing={2}>
        <RowData
          title='Completed lesson'
          value={<CompleteLessonCheck value={[]} />}
          loading={loading}
        />
        <Grid item marginTop='20px' xs={12} sm={12} md={12} lg={12} />
        <RowData
          title='Accessment (Self-help)'
          value={<SelectAccessment studentProfile={studentProfile} />}
          loading={loading}
        />
        <Grid item marginTop='20px' xs={12} sm={12} md={12} lg={12} />
        <RowData
          title='Instructor comment'
          value={
            <Tooltip placement='top' title='Edit comment' arrow>
              <span
                className='inst-comment'
                onClick={() => setOpenInstComment(true)}
                onKeyUp={() => {}}
                tabIndex={0}
                role='button'
              >
                <div style={{ paddingTop: 5 }}>
                  <BiEdit size={20} />
                </div>
                <div>{studentProfile?.instructor_comment}</div>
              </span>
            </Tooltip>
          }
          loading={loading}
        />
        <Grid item xs={12} sm={12} md={12} lg={12} marginTop='20px'>
          {isAbleCreate && (
            <LoadingButton
              className='ssc_text_field'
              size='small'
              loading={loadingCreate}
              variant='contained'
              onClick={handleCreate}
              disableElevation
            >
              Create Certificate
            </LoadingButton>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default CompleteLesson;
