import { lazy } from "react";

// import Schedule from "./schedule/Schedule";
// import Dashboard from "./dashboard/Dashboard";
// import User from "./user/User";
// import PrintSchedule from "./printschedule/PrintSchedule";
// import Location from "./location/Location";
// import Pool from "./pool/Pool";
// import Instructor from "./instructor/Instructor";
// import Course from "./course/Course";
// import Invoice from "./invoice/Invoice";
// import Feedback from "./feedback/Feedback";
// import Student from "./student/Student";
import StudentProfile from "./studentprofile/StudentProfile";
import ErrorPage from "./error-page/ErrorPage";
import ControlInvoice from "./controlinvoice/ControlInvoice";
import Certificate from "./certificate/Certificate";
import StaffCard from "./staff-card/StaffCard";
import Login from "./login/Login";
// import NewFeedback from "./new-feedback/NewFeedBack";
// import FeedbackInstructor from "./feedback-instructor/FeedbackInstructor";
// import FeedbackService from "./feedback-service/FeedbackService";
// import FeedbackFee from "./feedback-fee/FeedbackFee";
// import FeedbackPool from "./feedback-pool/FeedbackPool";
import FeedbackDetail from "./feedback-detail/FeedbackDetail";
// import NewStudent from "./new-student/NewStudent";
// import SwimmerReport from "./swimmer-report/SwimmerReport";
import NewStudentProfile from "./new-studentprofile/NewStudentProfile";

const Student = lazy(() => import("./student/Student"));
const Schedule = lazy(() => import("./schedule/Schedule"));
const Dashboard = lazy(() => import("./dashboard/Dashboard"));
const User = lazy(() => import("./user/User"));
const PrintSchedule = lazy(() => import("./printschedule/PrintSchedule"));
const Location = lazy(() => import("./location/Location"));
const Pool = lazy(() => import("./pool/Pool"));
const Instructor = lazy(() => import("./instructor/Instructor"));
const Course = lazy(() => import("./course/Course"));
const Invoice = lazy(() => import("./invoice/Invoice"));
const Feedback = lazy(() => import("./feedback/Feedback"));
const NewFeedback = lazy(() => import("./new-feedback/NewFeedBack"));
const FeedbackInstructor = lazy(() => import("./feedback-instructor/FeedbackInstructor"));
const FeedbackService = lazy(() => import("./feedback-service/FeedbackService"));
const FeedbackFee = lazy(() => import("./feedback-fee/FeedbackFee"));
const FeedbackPool = lazy(() => import("./feedback-pool/FeedbackPool"));
const NewStudent = lazy(() => import("./new-student/NewStudent"));
const SwimmerReport = lazy(() => import("./swimmer-report/SwimmerReport"));
const DeactivatedStudent = lazy(() => import("./deactivated-student/DeactivatedStudent"));
const UnderDevelop = lazy(() => import("./user-develop/UnderDevelop"));

export {
  Schedule,
  Dashboard,
  User,
  PrintSchedule,
  Location,
  Pool,
  Instructor,
  Course,
  Invoice,
  Feedback,
  Student,
  StudentProfile,
  ErrorPage,
  ControlInvoice,
  Login,
  NewFeedback,
  FeedbackInstructor,
  FeedbackService,
  FeedbackFee,
  FeedbackPool,
  FeedbackDetail,
  NewStudent,
  SwimmerReport,
  NewStudentProfile,
  DeactivatedStudent,
  UnderDevelop,
  Certificate,
  StaffCard,
};
