import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { PuffLoader } from "react-spinners";
import moment from "moment";
import { useRequest } from "ahooks";
// import { useReactToPrint } from "react-to-print";
import downloadjs from "downloadjs";
import html2canvas from "html2canvas";
import "./Static.scss";
import { BackStaffCard, FrontStaffCard } from "../../assets";
import { Instructor } from "../../hooks/model";
import { getImageUrl } from "../../utils/getUrl";

function StaffCard() {
  const { id } = useParams();
  const printRef = useRef();
  const [instructor, setInstructor] = useState(null);
  // please change to create user
  const { loading, run } = useRequest(Instructor.getOne, {
    manual: true,
    onSuccess: (data) => {
      if (data?.status) {
        setInstructor(data?.data);
      }
    },
  });

  useEffect(() => {
    if (id) {
      run({
        instId: id,
      });
    }
  }, [id]);

  useEffect(() => {
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
    return () => {
      document.removeEventListener("contextmenu");
    };
  }, []);

  const handleCaptureClick = async () => {
    const printContent = document.querySelector(".print-body");
    if (!printContent) return;

    const canvas = await html2canvas(printContent, {
      allowTaint: true,
      useCORS: true,
      scale: 10,
    });
    const dataURL = canvas.toDataURL("image/png");
    downloadjs(dataURL, `${instructor?.name?.split(" ").join("-")}-ID-Card.png`, "image/png");
  };

  // const handlePrint = useReactToPrint({
  //   content: () => printRef.current,
  //   documentTitle: `${instructor?.name}-ID-Card`,
  //   onAfterPrint: () => {
  //     window.close();
  //   },
  //   pageStyle: `
  //       @page {
  //         size: 60.03mm 92.03mm portrait;
  //         margin: 0px;
  //       }
  //       `,
  // });

  useEffect(() => {
    if (instructor) {
      handleCaptureClick();
    }
  }, [instructor, handleCaptureClick]);

  if (loading)
    return (
      <div className='loading-container'>
        <PuffLoader color='#FFFFFF' />
      </div>
    );

  if (!instructor)
    return (
      <div className='loading-container' style={{ color: "#FFFFFF" }}>
        <div style={{ textAlign: "center" }}>
          <h3>Something wrong!</h3>Please make sure you generate staff card.
        </div>
      </div>
    );

  return (
    <div className='ssc_stcard_container'>
      {/* <div style={{ display: "none" }}> */}
      <div className='print-body' id='print-content' ref={printRef}>
        <div style={{ position: "relative" }}>
          <img
            style={{
              width: "100%",
            }}
            src={FrontStaffCard}
            alt='image23-card'
          />

          <div className='image-container'>
            <div className='image-worker'>
              {instructor?.image !== "instructor-profile/default.png" ? (
                <img className='worker_image' src={getImageUrl(instructor?.image)} alt='' />
              ) : null}
            </div>
          </div>

          <div className='text-container'>
            <div
              className='worker_name'
              style={{
                fontSize: instructor?.name?.length > 17 ? 26 : 30,
              }}
            >
              {instructor?.name}
            </div>
            <div className='worker_position'>{instructor?.post}</div>
          </div>

          <div className='card-expire-date'>
            Expired Date: {moment(instructor?.card_expire_date).format("DD MMMM YYYY")}
          </div>
        </div>

        {/* <div className='image-rotate' /> */}
        <img
          style={{
            width: "100%",
          }}
          src={BackStaffCard}
          alt='image23-card'
          className='back-image'
        />
      </div>
      {/* </div> */}
    </div>
  );
}

export default StaffCard;
