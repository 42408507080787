import { Grid, Typography } from "@mui/material";

export default function TotalNumber({ title, total }) {
  return (
    <Grid container mb={1} bgcolor='#eeeeee' p={1}>
      <Grid item xs={6} md={3} lg={4}>
        <Typography>{title || "---"}</Typography>
      </Grid>
      <Grid item xs={1} md={1} lg={1} textAlign='center'>
        :
      </Grid>
      <Grid item xs={3} md={3} lg={3}>
        <Typography>{total}</Typography>
      </Grid>
    </Grid>
  );
}
