import { Box, Divider, Grid, Skeleton, Typography } from "@mui/material";
import { useRequest } from "ahooks";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
// import { animateScroll as scroll } from "react-scroll";
import moment from "moment";
import { AiFillEdit } from "react-icons/ai";
import { LoadingButton } from "@mui/lab";

import { Feedback as FeedbackDB } from "../../hooks/model";
import "./Static.scss";
import { BreadCrumb, ColorRateNumber } from "../../components";
import Create from "./modal/Create";

function RowDataMain({ title, value, loading }) {
  return (
    <>
      <Grid item xs={5} sm={4} md={2} lg={2}>
        {!loading ? (
          <Typography fontWeight='bold' variant='body1' gutterBottom>
            {title}
          </Typography>
        ) : (
          <Skeleton animation='wave' />
        )}
      </Grid>
      <Grid item xs={1} sm={1} md={1} lg={1}>
        :
      </Grid>
      <Grid item xs={6} sm={7} md={9} lg={9}>
        {!loading ? value : <Skeleton animation='wave' />}
      </Grid>
    </>
  );
}

function RowData({ title, value, loading }) {
  return (
    <>
      <Grid item xs={6} sm={6} md={4} lg={4}>
        {!loading ? (
          <Typography fontWeight='bold' variant='body1' paddingLeft={1} gutterBottom>
            {title}
          </Typography>
        ) : (
          <Skeleton animation='wave' />
        )}
      </Grid>
      <Grid item xs={1} sm={1} md={1} lg={1}>
        :
      </Grid>
      <Grid item xs={5} sm={5} md={7} lg={7}>
        {!loading ? value : <Skeleton animation='wave' />}
      </Grid>
    </>
  );
}

function FeedbackDetail() {
  const { feedbackId } = useParams();
  const [openUpdate, setOpenUpdate] = useState(false);
  const [formType, setFormType] = useState(null);

  const { loading, data, refresh } = useRequest(FeedbackDB.getOne, {
    defaultParams: [{ f_id: feedbackId }],
    // pollingInterval: 120000,
  });

  const feedbackData = useMemo(() => data?.data, [data?.data]);

  const handleOpenUpdate = () => {
    setFormType("update");
    setOpenUpdate(true);
  };

  return (
    <Box>
      <Create
        open={openUpdate}
        setOpen={setOpenUpdate}
        fetchData={refresh}
        selectData={{
          campaign: feedbackData?.campaign,
          instructor: feedbackData?.instructor,
          f_id: feedbackData?.f_id,
        }}
        type={formType}
      />

      <Box className='ssc-feedback-detail-container' style={{ padding: 25 }}>
        <Grid container style={{ marginBottom: 25 }}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <BreadCrumb pageTitle={`FEEDBACK - DETAIL / (${feedbackId})`} isLoading={loading} />
            {/* <Box>
              <Typography
                variant='h5'
                className='profile-title'
                style={{ fontWeight: "bolder", fontSize: 20, fontFamily: "KhmerOsContent" }}
              >
                {!loading ? (
                  `FEEDBACK - DETAIL / (${feedbackId})`
                ) : (
                  <Skeleton variant='text' animation='wave' />
                )}
              </Typography>
            </Box> */}
          </Grid>
          <Grid item xs={0} sm={6} md={6} lg={6} />
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box className='feedback-detail'>
              <Box className='feedback-image-container'>
                <LoadingButton
                  className='feedback-edit-btn'
                  color='info'
                  size='small'
                  loading={loading}
                  variant='outlined'
                  onClick={() => handleOpenUpdate()}
                  startIcon={<AiFillEdit />}
                >
                  Edit
                </LoadingButton>
              </Box>

              <Box style={{ marginTop: 40 }}>
                <Grid container columnSpacing={2}>
                  <RowDataMain title='Campaign' value={feedbackData?.campaign} loading={loading} />
                  <RowDataMain
                    title='Date'
                    value={moment(feedbackData?.f_date).format("DD-MMM-YYYY")}
                    loading={loading}
                  />
                </Grid>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />

                <Grid container columnSpacing={2}>
                  <RowDataMain
                    title='Location'
                    value={feedbackData?.location?.location}
                    loading={loading}
                  />
                  <RowDataMain
                    title='Pool name'
                    value={feedbackData?.pool?.poolName}
                    loading={loading}
                  />
                  <RowDataMain
                    title='Instructor'
                    value={feedbackData?.instructor?.name}
                    loading={loading}
                  />
                </Grid>

                <Grid mt={2} container columnSpacing={2}>
                  {/* Customer service  */}
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Box className='feedback-detail-title'>
                      Customer Service / Administrator: (1=Poor, 10=Excellent)
                    </Box>

                    <Grid mt={2} container columnSpacing={2}>
                      <RowData
                        title='Greeting & Phone'
                        value={<ColorRateNumber rate={feedbackData?.cs_greetPhone} />}
                        loading={loading}
                      />
                      <RowData
                        title='Speaking Clear'
                        value={<ColorRateNumber rate={feedbackData?.cs_speakingClear} />}
                        loading={loading}
                      />
                      <RowData
                        title='Helpful'
                        value={<ColorRateNumber rate={feedbackData?.cs_helpful} />}
                        loading={loading}
                      />
                      <RowData
                        title='Answer on Time'
                        value={<ColorRateNumber rate={feedbackData?.cs_answerOnTime} />}
                        loading={loading}
                      />
                      <RowData
                        title='Information detail'
                        value={<ColorRateNumber rate={feedbackData?.cs_infoDetail} />}
                        loading={loading}
                      />
                      <RowData title='Comment' value={feedbackData?.cs_comment} loading={loading} />
                    </Grid>
                  </Grid>

                  {/* Instructor */}
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Box className='feedback-detail-title'>Instructor: (1=Poor, 10=Excellent)</Box>

                    <Grid mt={2} container columnSpacing={2}>
                      <RowData
                        title='Personality / Friendly'
                        value={<ColorRateNumber rate={feedbackData?.ins_personality} />}
                        loading={loading}
                      />
                      <RowData
                        title='Skill & Teaching'
                        value={<ColorRateNumber rate={feedbackData?.ins_teachSkill} />}
                        loading={loading}
                      />
                      <RowData
                        title='Speaking Clear'
                        value={<ColorRateNumber rate={feedbackData?.ins_speakingClear} />}
                        loading={loading}
                      />
                      <RowData
                        title='Time of Class'
                        value={<ColorRateNumber rate={feedbackData?.ins_timeClass} />}
                        loading={loading}
                      />
                      <RowData
                        title='Corrective Feedback'
                        value={<ColorRateNumber rate={feedbackData?.ins_corrective} />}
                        loading={loading}
                      />
                      <RowData
                        title='Comment'
                        value={feedbackData?.ins_comment}
                        loading={loading}
                      />
                    </Grid>
                  </Grid>

                  {/* Class fee */}
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Box className='feedback-detail-title'>Class Fee: (1=Poor, 10=Excellent)</Box>
                    <Grid mt={2} container columnSpacing={2}>
                      <RowData title='Class Name' value={feedbackData?.classId} loading={loading} />
                      <RowData
                        title='Class Rating'
                        value={<ColorRateNumber rate={feedbackData?.classRate} />}
                        loading={loading}
                      />
                      <RowData
                        title='Comment'
                        value={feedbackData?.classComment}
                        loading={loading}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                <Grid container>
                  <RowDataMain
                    title='Recommend?'
                    value={feedbackData?.recommend}
                    loading={loading}
                  />
                  <RowDataMain
                    title='Remark'
                    value={feedbackData?.final_comment}
                    loading={loading}
                  />
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default FeedbackDetail;
