import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useRequest } from "ahooks";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
// import { MdOutlineExpandMore } from "react-icons/md";
import { CgAdd } from "react-icons/cg";
// import { toast } from "react-toastify";
import { TbFileDollar } from "react-icons/tb";
import { toast } from "react-toastify";
import { useSetRecoilState } from "recoil";
import { CustomTable } from "../../../components";
import { Student as StudentDB, Invoice as InvoiceDB } from "../../../hooks/model";
import { StudendPaymentRecoil } from "../../../recoils";
import { openNewTab } from "../../../utils/function";

import "./Static.scss";

function Payment({ studentId, courseId, startDate, endDate }) {
  const [totalPending, setTotalPending] = useState(-1);
  const setStudentPaymentState = useSetRecoilState(StudendPaymentRecoil.studentPaymentState);
  // Table header
  const headers = [
    {
      key: "no",
      title: "N°",
      width: 50,
      align: "center",
    },
    {
      key: "created",
      title: "Name",
      width: 100,
      render: (record) => {
        return (
          <div>
            {record?.created === "0000-00-00"
              ? "Unsaved"
              : moment(record?.created).format("DD-MMM-YYYY")}
          </div>
        );
      },
    },
    {
      key: "action",
      title: "Action",
      fixed: true,
      width: 30,
      render: (record) => {
        return (
          <Stack display='flex' alignItems='center' justifyContent='center' direction='row'>
            <IconButton
              aria-label='edit'
              color='success'
              size='small'
              onClick={() => {
                if (record?.status === "paid") {
                  openNewTab({
                    url: `/view-invoice?studentId=${studentId}&invoiceId=${record?.id}`,
                  });
                  return null;
                }
                openNewTab({
                  url: `/view-invoice?studentId=${studentId}&invoiceId=${record?.id}&courseId=${courseId}`,
                });
                return null;
              }}
            >
              <AiOutlineEye />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const { data, loading, refresh } = useRequest(StudentDB.getPayment, {
    onSuccess: (res) => {
      if (res?.status) {
        setStudentPaymentState({ invoices: res?.data || [] });
        setTotalPending(res?.data?.filter((e) => e?.status === "pending")?.length);
      }
    },
    defaultParams: [{ studentId }],
    pollingInterval: 30000,
    refreshOnWindowFocus: true,
    cacheKey: `student-payment-${studentId}`,
  });

  const { loading: loadingCreate, run: runCreate } = useRequest(InvoiceDB.create, {
    manual: true,
    onSuccess: (res) => {
      if (res?.status && parseInt(res?.data?.invoiceId, 10) > 0) {
        refresh();
        openNewTab({
          url: `/view-invoice?studentId=${studentId}&invoiceId=${res?.data?.invoiceId}&courseId=${courseId}`,
        });
      }
    },
  });
  const handleCreate = () => {
    if (startDate === "0000-00-00" || endDate === "0000-00-00") {
      toast.warn("Student has no Start date, End date!");
      return;
    }
    if (Number(courseId) <= 0) {
      toast.warn("Student has no class!");
      return;
    }
    runCreate({ studentId, courseId });
  };

  const AddPayment = useMemo(() => {
    return totalPending === 0 ? (
      <AccordionSummary
        expandIcon={
          <IconButton
            onClick={handleCreate}
            disabled={loadingCreate}
            aria-label='edit'
            color='success'
            size='default'
          >
            <CgAdd />
          </IconButton>
        }
        aria-controls='panel1a-content'
        id='panel1a-header'
      />
    ) : null;
  }, [totalPending, loadingCreate]);

  const dataOrder = data?.data?.map((record, index) => {
    return { ...record, no: index + 1 };
  });

  return (
    <Box className='student-profile-payment' style={{ background: "#ffffff" }}>
      <Accordion square expanded>
        <AccordionSummary
          expandIcon={AddPayment}
          aria-controls='panel1a-content'
          id='panel1a-header'
          // onClick={() => setExpand(!expand)}
        >
          <Typography variant='h6' className='student-profile-payment-title'>
            <TbFileDollar />
            Payment
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CustomTable
            className='student-profile-payment-table'
            columns={headers}
            dataSource={dataOrder || []}
            noHeaders
            isLoading={loading}
            skeletonCount={5}
            rowKeys={(record) => record?.id}
            rowClassNames={(record) => {
              if (record?.status === "pending") return "row-danger";

              return "";
            }}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default Payment;
