import React from "react";
import { useRecoilValue } from "recoil";
import { User as UserAuth } from "../../../recoils";

function Signature() {
  const auth = useRecoilValue(UserAuth.userState);
  return (
    <table className='sign' style={{ fontSize: 13 }}>
      <tbody>
        <tr>
          <td style={{ width: "50%", padding: "0px" }} />
          <td style={{ width: "50%", padding: "0px" }}>
            <span>{auth?.username?.toUpperCase()}</span>
          </td>
        </tr>
        <tr>
          <td style={{ width: "50%", padding: "0px" }}>
            {/* <div className='line-sign' />
            <p>
              <span className='info-text-kh'>ហត្ថាលេខា និងឈ្មោះអតិថិជន</span>
              <br />
              <span className='info-text'>Customer&apos;s signature &amp; name</span>
            </p> */}
          </td>
          <td style={{ width: "50%", padding: "0px" }}>
            <div className='line-sign' />
            <p>
              <span className='info-text-kh'>ហត្ថាលេខា និងឈ្មោះអ្នកលក់</span>
              <br />
              <span className='info-text'>Seller&apos;s signature &amp; name</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default Signature;
