import { Box, Grid, InputBase, Paper } from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ImSearch } from "react-icons/im";
import { useRecoilState } from "recoil";
import { ControlDropDatePicker, SelectLocation } from "../../components";
import { queryURL } from "../../recoils";

const initialState = {
  location_id: null,
  start_date: null,
  end_date: null,
  search: "",
};

function Filter() {
  const [query, setQuery] = useRecoilState(queryURL);

  const { control, register, watch } = useForm({
    resolver: null,
    defaultValues: initialState,
  });

  const handleSetQuery = (value) => {
    const keyValue = Object.keys(value);
    const newObj = {};
    keyValue.forEach((load) => {
      if (value[load]) {
        newObj[load] = value[load];
      }
    });
    if (Object.keys(newObj).length > 0) {
      setQuery(newObj);
    }
  };

  useEffect(() => {
    const start_date = watch("start_date");
    const location_id = watch("location_id");
    handleSetQuery({
      location_id,
      start_date: start_date ? moment(start_date).format("YYYY-MM-DD") : null,
    });
  }, [watch("start_date"), watch("location_id")]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
        <ControlDropDatePicker name='start_date' label='' control={control} />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
        <ControlDropDatePicker
          name='end_date'
          label=''
          disabled={!query?.start_date}
          minDate={query?.start_date || null}
          control={control}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
        <SelectLocation name='location_id' label='' control={control} disableClearable={false} />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
        <Paper
          className='ssc_search_paper ssc_text_field'
          sx={{ p: "0px 4px", display: "flex", alignItems: "center" }}
        >
          <Box sx={{ p: "10px" }}>
            <ImSearch />
          </Box>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder='Search...'
            inputProps={{ "aria-label": "search" }}
            {...register("search")}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Filter;
