import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useSearchParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { ResetButton } from "../../recoils";
import { checkObjectValue, keyMenu } from "../../utils/function";

export function useSetSearchParams({ initialState, schema }) {
  const setResetState = useSetRecoilState(ResetButton.resetButtonState);
  const [search, setSearch] = useSearchParams();
  const searchParams = Object.fromEntries([...search]);
  const keyPath = keyMenu(useLocation().pathname);

  const useFormRes = useForm({
    defaultValues: {
      ...initialState,
    },
    resolver: schema ? yupResolver(schema) : null,
  });

  const handleSetSearch = ({ key = [], value }) => {
    if (!checkObjectValue(value)) {
      key?.forEach((load) => {
        search.delete(load);
      });
      setSearch(search, { replace: true });
      return;
    }

    setSearch(
      {
        ...searchParams,
        ...value,
      },
      { replace: true }
    );
  };

  const resetSearch = () => {
    useFormRes.reset();
    setSearch({}, { replace: true });
    setResetState({
      routeKey: "",
      action: () => {},
      isIn: false,
    });
  };

  useEffect(() => {
    if (Object.keys(searchParams).length > 0) {
      setResetState(() => ({
        routeKey: keyPath,
        isIn: true,
        action: resetSearch,
      }));
      return;
    }

    setResetState({
      routeKey: "",
      isIn: false,
      action: () => {},
    });
  }, [searchParams, keyPath]);

  return { searchParams, handleSetSearch, ...useFormRes, resetSearch };
}
