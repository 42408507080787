import { useEffect, useRef, Suspense } from "react";
import { useRecoilValue } from "recoil";
import "./styles/theme.scss";
import { Routes, Route, useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { PhotoProvider } from "react-photo-view";
import {
  Schedule,
  PrintSchedule,
  User,
  Location,
  Pool,
  Instructor,
  Course,
  Invoice,
  Feedback,
  Student,
  Dashboard,
  StudentProfile,
  ControlInvoice,
  Login,
  NewFeedback,
  FeedbackInstructor,
  FeedbackService,
  FeedbackFee,
  FeedbackPool,
  FeedbackDetail,
  NewStudent,
  SwimmerReport,
  NewStudentProfile,
  DeactivatedStudent,
  Certificate,
  StaffCard,
  // ErrorPage,
} from "./pages";
import { ControlLinearLoading, FloabButton, Header, NotFound, SideBar } from "./components/index";
import { goToTop } from "./utils/function";
import { User as UserAuth } from "./recoils";
import { useExpiredStorage } from "./hooks";
import MessageExpireSession from "./common/shared/messageExpireSession";
import StudentCardPage from "./pages/student-card/StudentCard";

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          overflowY: "visible!important",
        },
      },
    },
  },
});

function RouteConfig() {
  // const [toggled, setToggled] = useState(false);
  const fullContainerRef = useRef(null);
  const location = useLocation();
  const auth = useRecoilValue(UserAuth.userState);

  // check if user session is expired
  useExpiredStorage();

  useEffect(() => {
    if (Notification.permission !== "granted" && auth) {
      Notification.requestPermission();
    }
  }, [auth]);

  useEffect(() => {
    if (auth) {
      goToTop(fullContainerRef.current);
    }
  }, [location?.pathname]);

  if (!auth) {
    return <Login />;
  }

  return (
    <div className='app'>
      <MessageExpireSession />
      {/* <Snowfall
        snowflakeCount={150}
        color='#ffffff'
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
        }}
      /> */}
      <PhotoProvider maskOpacity={0.8}>
        <ThemeProvider theme={theme}>
          <SideBar />

          <Box
            ref={fullContainerRef}
            style={{ padding: "0px 0px", width: "100%", overflowY: "auto" }}
          >
            <Header />

            <Suspense fallback={<ControlLinearLoading />}>
              <Routes>
                <Route path='/' element={<Dashboard to='schedule' />} />
                <Route path='/schedule'>
                  <Route index element={<Schedule />} />
                  <Route path='print' element={<PrintSchedule />} />
                </Route>

                {auth?.post === "admin" && <Route path='/user' element={<User />} />}
                {(auth?.post === "admin" || auth?.post === "user") && (
                  <Route path='/instructor' element={<Instructor />} />
                )}

                <Route path='/location' element={<Location />} />
                <Route path='/pool' element={<Pool />} />

                <Route path='/course' element={<Course />} />
                <Route path='/invoice' element={<Invoice />} />
                <Route path='/feedback'>
                  <Route index element={<Feedback />} />
                  <Route path=':feedbackId' element={<FeedbackDetail />} />
                </Route>
                <Route path='/new-feedback' element={<NewFeedback />} />
                <Route path='/feedback-service' element={<FeedbackService />} />
                <Route path='/feedback-instructor' element={<FeedbackInstructor />} />
                <Route path='/feedback-fee' element={<FeedbackFee />} />
                <Route path='/feedback-pool' element={<FeedbackPool />} />
                <Route path='/view-invoice'>
                  <Route index element={<ControlInvoice />} />
                </Route>
                <Route path='/swimmer'>
                  <Route index element={<Student />} />
                  <Route path=':studentId' element={<StudentProfile />} />
                </Route>
                <Route path='/deactivated'>
                  <Route index element={<DeactivatedStudent />} />
                </Route>
                <Route path='/new'>
                  <Route index element={<NewStudent />} />
                  <Route path=':studentId' element={<NewStudentProfile />} />
                </Route>

                <Route path='/swimmer-report'>
                  <Route index element={<SwimmerReport />} />
                  <Route path=':studentId' element={<StudentProfile />} />
                </Route>

                <Route path='/certificate'>
                  <Route path=':id' element={<Certificate />} />
                </Route>
                <Route path='/staff-card'>
                  <Route path=':id' element={<StaffCard />} />
                </Route>
                <Route path='/student-card'>
                  <Route path=':id' element={<StudentCardPage />} />
                </Route>

                <Route path='*' element={<NotFound />} />
              </Routes>
            </Suspense>
            {/* reset filter button */}
            <FloabButton />
          </Box>
        </ThemeProvider>
      </PhotoProvider>
    </div>
  );
}

export default RouteConfig;
