import Init from "./init";

class Course {
  static initPath = (path) => {
    return `/api/courses${path}`;
  };

  static getByPage = async ({ page, pageSize, keyword, location_id, status }) => {
    const data = { page, pageSize, keyword, location_id, status };
    const res = await Init.fnInit(this.initPath("/getPaginator"), data);

    return res;
  };

  static getAll = async () => {
    const res = await Init.fnInit(this.initPath("/getAll"));

    return res;
  };

  static add = async ({
    type,
    type_kh,
    day,
    day_kh,
    duration,
    duration_kh,
    price,
    per,
    per_kh,
    location_id,
  }) => {
    const data = {
      type,
      type_kh,
      day,
      day_kh,
      duration,
      duration_kh,
      price,
      per,
      per_kh,
      location_id,
    };
    const res = await Init.fnInit(this.initPath("/create"), data);

    return res;
  };

  static update = async (data) => {
    const res = await Init.fnInit(this.initPath("/update"), data);

    return res;
  };

  static delete = async ({ id }) => {
    const data = { id };
    const res = await Init.fnInit(this.initPath("/delete"), data);

    return res;
  };
}

export default Course;
