import ExpiredStorage from "expired-storage";
import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import { USER_LOCAL_STORAGE_KEY } from "../../constants";

const { persistAtom } = recoilPersist();

const getLocalData = () => {
  const expiredStorage = new ExpiredStorage();
  return expiredStorage.getJson(USER_LOCAL_STORAGE_KEY);
};

export const userState = atom({
  key: "userAtom",
  default: getLocalData() || null,
});

export const haveBeenAlertedAtom = atom({
  key: "haveBeenAlertedAtom",
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const isShowMessageAtom = atom({
  key: "isShowMessageAtom",
  default: false,
  // effects_UNSTABLE: [persistAtom],
});
