import { Divider, IconButton, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";

export default function ControlMenu({ menuItems = [], menuIcon, menuProps }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <IconButton
        onClick={handleOpenMenu}
        aria-controls='simple-menu'
        aria-haspopup='true'
        aria-label='directions'
      >
        {menuIcon !== null && menuIcon !== undefined ? menuIcon : <BsThreeDotsVertical />}
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        PaperProps={menuProps}
        open={openMenu}
        onClose={() => setAnchorEl(null)}
        onBlur={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {menuItems?.map((load) => (
          <div key={load.id}>
            <MenuItem
              onClick={() => {
                load?.action();
                setAnchorEl(null);
              }}
            >
              <ListItemIcon>{load.icon}</ListItemIcon>
              <Typography variant='inherit' noWrap>
                {load.title}
              </Typography>
            </MenuItem>
            {load?.isDividerBottom ? <Divider /> : null}
          </div>
        ))}
      </Menu>
    </>
  );
}
