import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useRequest } from "ahooks";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { BsBoxArrowInDown } from "react-icons/bs";
import { IoCloseCircle } from "react-icons/io5";
import { toast } from "react-toastify";
import { VscCloudUpload } from "react-icons/vsc";

import { Student as StudentDB } from "../../../hooks/model";
import { PrimaryModal } from "../../../components";
import SheetDropGIF from "../../../assets/gif/sheet-drop.gif";
import CheckedAnimation from "../../../assets/gif/checked-animation.gif";

import "./Static.scss";
import { compressImage, isImageType } from "../../../utils/function";

function UpdateImage({ setOpen, open, fetchData, selectData }) {
  const [profileFile, setProfileFile] = useState(null);

  const onDrop = useCallback(async (acceptedFiles) => {
    // Do something with the files
    const imageFile = acceptedFiles[0];

    if (!isImageType(imageFile)) {
      return;
    }
    const compressedImage = await compressImage(imageFile);
    setProfileFile(compressedImage);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const reqSuccess = (data) => {
    if (data?.status) {
      fetchData();
      toast.success(data?.message);
      setOpen(false);
      setProfileFile(null);
    }
  };

  // please change to create user
  const { loading: updateLoading, run: onUpdate } = useRequest(StudentDB.updateProfileImage, {
    manual: true,
    onSuccess: (data) => reqSuccess(data),
  });

  const onSubmit = () => {
    if (!profileFile) {
      toast.error("No file selected!");
      return;
    }
    onUpdate({
      ...selectData,
      profile_file: profileFile,
      old_image: selectData?.profile_img,
    });
  };

  const checkFile = () => {
    if (profileFile) {
      return <img src={CheckedAnimation} width={80} alt='checked file' />;
    }

    return <BsBoxArrowInDown size={60} />;
  };

  return (
    <PrimaryModal
      open={open}
      setOpen={setOpen}
      title={
        <Grid container spacing={2}>
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <Stack direction='row' gap={1} alignItems='center'>
              <VscCloudUpload size={30} />
              <Typography variant='h5' component='div'>
                UPLOAD IMAGE
              </Typography>
            </Stack>

            <Typography variant='subtitle2' gutterBottom component='div'>
              STUDENT ID: {selectData.studentId}
            </Typography>
          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2}>
            <Stack direction='row' justifyContent='flex-end' alignItems='center'>
              <IconButton color='error' onClick={() => setOpen(false)}>
                <IoCloseCircle />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={2}>
        {/* image drop */}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div {...getRootProps()} className={`drop-image-zone ${isDragActive ? "active" : ""}`}>
            <input {...getInputProps()} />

            <Box textAlign='center'>
              {isDragActive ? (
                <img src={SheetDropGIF} width={80} alt='drop file' />
              ) : (
                <p>{checkFile()}</p>
              )}

              {profileFile ? <p>File has been selected.</p> : ""}
              <p>
                <b>Drag &apos;n&apos; drop</b> some files here, or <b>click</b> to select files
              </p>
            </Box>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <LoadingButton
            style={{ width: "100%" }}
            className='ssc_text_field'
            size='small'
            variant='contained'
            disableElevation
            loading={updateLoading}
            onClick={onSubmit}
          >
            UPLOAD
          </LoadingButton>
        </Grid>
      </Grid>
    </PrimaryModal>
  );
}

export default UpdateImage;
