import Init from "./init";
import { randomFn } from "./randomFn";

class Report {
  static initPath = (path) => {
    return `/api/reports${path}`;
  };

  static studentPaginator = async (data) => {
    const res = await Init.fnInit(this.initPath("/studentPaginator"), data);

    // wait...
    await randomFn();

    return res;
  };

  static studentTotalByLocation = async (data = {}) => {
    const res = await Init.fnInit(this.initPath("/studentTotalByLocation"), data);

    // wait...
    await randomFn();

    return res;
  };

  static add = async (data) => {
    const res = await Init.fnInit(this.initPath("/create"), data);

    return res;
  };
}

export default Report;
