import React from "react";
import "./Static.scss";

function ErrorDisplay({ description }) {
  return (
    <div className='loading-container' style={{ color: "#FFFFFF" }}>
      <div style={{ textAlign: "center" }}>{description}</div>
    </div>
  );
}

export default ErrorDisplay;
