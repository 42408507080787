import { Divider, Grid, IconButton, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { IoCloseCircle } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
import { useRequest } from "ahooks";
import { toast } from "react-toastify";

import { Student as StudentDB } from "../../../hooks/model";

import {
  ControlCheckBoxGroup,
  ControlDrawer,
  ControlDropDatePicker,
  ControlRadioGroup,
  ControlSelect,
  ControlSwitch,
  SelectCourse,
  SelectInstructor,
  SelectLocation,
} from "../../../components";
import "./Static.scss";
import {
  class_types,
  communicateLang,
  findus_options,
  genders,
  have_drown_options,
  have_train,
  healthinfo_options,
  parents,
  photo_permission,
  prefixes,
  skills_options,
} from "../../../constants";

const initialState = {
  st_prefix: "",
  st_name: "",
  st_status: false,
  st_gender: "",
  location_id: null,
  birthdate: "",
  start_date: null,
  end_date: null,
  classType: "",
  course_id: null,
  instructor_id: null,
  remark: "",
  dad_mum: "",
  parentname: "",
  address: "",
  contactnumber: "",
  emergcontact: "",
  nationality: "",
  relationship: "",
  facebook_url: "",
  telegram: "",
  havetrain: "",
  howlongtrain: "",
  knowskill: [],
  healthinfo: [],
  otherhealthinfo: "",
  havedrown: "",
  permission: "",
  findus: [],
  otherfindus: "",
  classoptional: "",
  friendname: "",
  school_workplace: "",
  is_organization: false,
  communicate_lang: "",
};

const schema = yup
  .object()
  .shape({
    st_name: yup.string().required("Name is required"),
    st_status: yup.bool(),
    is_organization: yup.bool(),
    st_prefix: yup.string().required("Prefix is required"),
    st_gender: yup.string().required("Gender is required"),
    location_id: yup.object().nullable().required("Location is required"),
    birthdate: yup.date().required("Date of birth is required"),
    start_date: yup.date().nullable().typeError("Must be date!"),
    end_date: yup.date().nullable().typeError("Must be date!"),
    classType: yup.string().required("Class type is required"),
    course_id: yup.object().nullable(),
    instructor_id: yup.object().nullable(),
    dad_mum: yup.string(),
    parentname: yup.string().required("Parent name is required"),
    address: yup.string().required("Address is required"),
    contactnumber: yup.string().required("Contact number is required"),
    emergcontact: yup.string().required("Emergency contact is required"),
    nationality: yup.string().required("Nationality is required"),
    relationship: yup.string(),
    facebook_url: yup.string(),
    telegram: yup.string(),
    havetrain: yup.string(),
    howlongtrain: yup.string(),
    knowskill: yup.array(),
    healthinfo: yup.array(),
    otherhealthinfo: yup.string(),
    havedrown: yup.string(),
    permission: yup.string(),
    findus: yup.array(),
    otherfindus: yup.string(),
    classoptional: yup.string().nullable(),
    friendname: yup.string(),
    school_workplace: yup.string(),
    communicate_lang: yup.string().required("Language is required"),
  })
  .required();

// .when("havetrain", {
//   is: "Yes",
//   then: yup.string().required("Nationality is required"),
// }),

function UpdateStudent({ open, setOpen, selectData, fetchData }) {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { ...initialState },
  });

  useEffect(() => {
    function initFunction() {
      reset();
      setValue("ssc_id", selectData?.ssc_id);
      setValue("st_name", selectData?.st_name);
      setValue("st_status", selectData?.st_status === "Active");
      setValue("is_organization", parseInt(selectData?.is_organization, 10) > 0);
      setValue("st_prefix", selectData?.st_prefix);
      setValue("st_gender", selectData?.st_gender);
      setValue("birthdate", selectData?.birthdate);

      setValue("classType", selectData?.classType);
      setValue("remark", selectData?.remark);
      setValue("dad_mum", selectData?.dad_mum);
      setValue("parentname", selectData?.parentname);
      setValue("address", selectData?.address);
      setValue("contactnumber", selectData?.contactnumber);
      setValue("emergcontact", selectData?.emergcontact);
      setValue("nationality", selectData?.nationality);
      setValue("relationship", selectData?.relationship);
      setValue("facebook_url", selectData?.facebook_url);
      setValue("telegram", selectData?.telegram);
      setValue("havetrain", selectData?.havetrain);
      setValue("howlongtrain", selectData?.howlongtrain);
      setValue("knowskill", selectData?.knowskill);
      setValue("healthinfo", selectData?.healthinfo);
      setValue("otherhealthinfo", selectData?.otherhealthinfo);
      setValue("havedrown", selectData?.havedrown);
      setValue("permission", selectData?.permission);
      setValue("findus", selectData?.findus);
      setValue("otherfindus", selectData?.otherfindus);
      setValue("classoptional", selectData?.classoptional);
      setValue("friendname", selectData?.friendname);
      setValue("school_workplace", selectData?.school_workplace);
      setValue("communicate_lang", selectData?.communicate_lang);

      setValue(
        "start_date",
        selectData?.start_date !== "0000-00-00" ? selectData?.start_date : null
      );
      setValue("end_date", selectData?.end_date !== "0000-00-00" ? selectData?.end_date : null);

      const locationSelect = selectData?.location
        ? {
            id: selectData?.location?.id,
            label: selectData?.location?.location,
          }
        : null;
      const instructorSelect = selectData?.instructor
        ? {
            id: selectData?.instructor?.id,
            label: selectData?.instructor?.name,
          }
        : null;
      const courseSelect = selectData?.course
        ? {
            id: selectData?.course?.id,
            label: `${selectData?.course?.type} (${selectData?.course?.day}) ${selectData?.course?.duration}, ${selectData?.course?.price}USD/${selectData?.course?.per}`,
          }
        : null;

      setValue("location_id", locationSelect);
      setValue("course_id", courseSelect);
      setValue("instructor_id", instructorSelect);
    }

    if (selectData) {
      initFunction();
    }
  }, [selectData, setValue, reset, open]);

  // please change to create user , run: onUpdate
  const { loading: updateLoading, run: onUpdate } = useRequest(StudentDB.update, {
    manual: true,
    onSuccess: (data) => {
      if (data) {
        fetchData();
        toast.success(data?.message);
        reset();
        setOpen(false);
      }
    },
  });

  const onSubmit = (values) => {
    const newData = {
      ...values,
      birthdate: moment(values?.birthdate).format("YYYY-MM-DD"),
      start_date:
        values?.start_date !== null
          ? moment(values?.start_date).format("YYYY-MM-DD")
          : "0000-00-00",
      end_date:
        values?.end_date !== null ? moment(values?.end_date).format("YYYY-MM-DD") : "0000-00-00",
      course_id: values?.course_id?.id || 0,
      location_id: values?.location_id?.id || 0,
      instructor_id: values?.instructor_id?.id || 0,
      st_status: values?.st_status ? "Active" : "Deactive",
    };

    onUpdate({
      ...selectData,
      ...newData,
    });
  };

  return (
    <ControlDrawer
      anchor='right'
      open={open}
      setOpen={setOpen}
      title={
        <Grid container spacing={2}>
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <Typography fontWeight='bold' variant='h6' gutterBottom>
              UPDATE
            </Typography>
          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2}>
            <Stack direction='row' justifyContent='flex-end' alignItems='center'>
              <IconButton color='error' onClick={() => setOpen(false)}>
                <IoCloseCircle />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            onChange={() => {}}
            size='small'
            label='ID'
            style={{ width: "100%" }}
            disabled
            {...register("ssc_id")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <ControlSelect
            name='st_prefix'
            control={control}
            required
            label='Prefix'
            error={!!errors?.st_prefix}
            helperText={<span>{errors?.st_prefix?.message}</span>}
            options={prefixes}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            error={!!errors?.st_name}
            helperText={<span>{errors?.st_name?.message}</span>}
            style={{ width: "100%" }}
            // value={formData.st_name}
            size='small'
            label='Name'
            required
            {...register("st_name")}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <ControlSelect
            name='st_gender'
            control={control}
            required
            label='Gender'
            error={!!errors?.st_gender}
            helperText={<span>{errors?.st_gender?.message}</span>}
            options={genders}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <SelectLocation
            name='location_id'
            control={control}
            required
            error={!!errors?.location_id}
            helperText={<span>{errors?.location_id?.message}</span>}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <ControlDropDatePicker
            name='birthdate'
            control={control}
            required
            label='Date of Birth'
            error={!!errors?.birthdate}
            helperText={<span>{errors?.birthdate?.message}</span>}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <ControlSelect
            name='communicate_lang'
            control={control}
            required
            label='Language'
            error={!!errors?.communicate_lang}
            helperText={<span>{errors?.communicate_lang?.message}</span>}
            options={communicateLang}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Divider />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <ControlSwitch
            name='st_status'
            control={control}
            required
            label='Status'
            error={!!errors?.st_status}
            helperText={<span>{errors?.st_status?.message}</span>}
            // color={watch("st_status") ? "success" : "error"}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <ControlSwitch
            name='is_organization'
            control={control}
            required
            label='Organization/Company'
            error={!!errors?.is_organization}
            helperText={<span>{errors?.is_organization?.message}</span>}
            // color={watch("st_status") ? "success" : "error"}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <ControlDropDatePicker
            name='start_date'
            control={control}
            // required
            label='Start date'
            error={!!errors?.start_date}
            helperText={<span>{errors?.start_date?.message}</span>}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <ControlDropDatePicker
            name='end_date'
            control={control}
            // required
            label='End date'
            error={!!errors?.end_date}
            helperText={<span>{errors?.end_date?.message}</span>}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <ControlSelect
            name='classType'
            control={control}
            required
            label='Class type'
            error={!!errors?.classType}
            helperText={<span>{errors?.classType?.message}</span>}
            options={class_types}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <SelectInstructor
            name='instructor_id'
            control={control}
            required={false}
            label='Instructor'
            error={!!errors?.instructor_id}
            helperText={<span>{errors?.instructor_id?.message}</span>}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <SelectCourse
            name='course_id'
            control={control}
            required
            label='Class'
            error={!!errors?.course_id}
            helperText={<span>{errors?.course_id?.message}</span>}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            error={!!errors?.classoptional}
            helperText={<span>{errors?.classoptional?.message}</span>}
            style={{ width: "100%" }}
            size='small'
            label='Class optional'
            {...register("classoptional")}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            error={!!errors?.remark}
            helperText={<span>{errors?.remark?.message}</span>}
            style={{ width: "100%" }}
            size='small'
            multiline
            rows={4}
            label='Remark'
            {...register("remark")}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Divider />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ControlRadioGroup
            name='dad_mum'
            control={control}
            required={false}
            // label='Parents'
            error={!!errors?.dad_mum}
            helperText={<span>{errors?.dad_mum?.message}</span>}
            options={parents}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            error={!!errors?.parentname}
            helperText={<span>{errors?.parentname?.message}</span>}
            style={{ width: "100%" }}
            size='small'
            required
            label='Parent name'
            {...register("parentname")}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            error={!!errors?.address}
            helperText={<span>{errors?.address?.message}</span>}
            style={{ width: "100%" }}
            // value={formData.address}
            required
            size='small'
            label='Address'
            {...register("address")}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            error={!!errors?.contactnumber}
            helperText={<span>{errors?.contactnumber?.message}</span>}
            style={{ width: "100%" }}
            required
            size='small'
            label='Contact'
            {...register("contactnumber")}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            error={!!errors?.emergcontact}
            helperText={<span>{errors?.emergcontact?.message}</span>}
            style={{ width: "100%" }}
            required
            size='small'
            label='Emergency contact'
            {...register("emergcontact")}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            error={!!errors?.nationality}
            helperText={<span>{errors?.nationality?.message}</span>}
            style={{ width: "100%" }}
            required
            size='small'
            label='Nationality'
            {...register("nationality")}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            error={!!errors?.relationship}
            helperText={<span>{errors?.relationship?.message}</span>}
            style={{ width: "100%" }}
            size='small'
            label='Relationship'
            {...register("relationship")}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            error={!!errors?.facebook_url}
            helperText={<span>{errors?.facebook_url?.message}</span>}
            style={{ width: "100%" }}
            size='small'
            label='Facebook url'
            {...register("facebook_url")}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            error={!!errors?.telegram}
            helperText={<span>{errors?.telegram?.message}</span>}
            style={{ width: "100%" }}
            size='small'
            label='Telegram'
            {...register("telegram")}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            error={!!errors?.school_workplace}
            helperText={<span>{errors?.school_workplace?.message}</span>}
            style={{ width: "100%" }}
            size='small'
            label='School/Workplace'
            {...register("school_workplace")}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Divider />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ControlSelect
            name='havetrain'
            control={control}
            required
            label='Ever trained swimming?'
            error={!!errors?.havetrain}
            helperText={<span>{errors?.havetrain?.message}</span>}
            options={have_train}
          />
        </Grid>

        {watch("havetrain") === "Yes" ? (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                error={!!errors?.howlongtrain}
                helperText={<span>{errors?.howlongtrain?.message}</span>}
                style={{ width: "100%" }}
                size='small'
                label='How long train?'
                {...register("howlongtrain")}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <ControlCheckBoxGroup
                name='knowskill'
                control={control}
                required={false}
                label='Learn skills'
                error={!!errors?.knowskill}
                helperText={<span>{errors?.knowskill?.message}</span>}
                options={skills_options?.filter((e) => e?.value)}
              />
            </Grid>
          </>
        ) : null}

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ControlCheckBoxGroup
            name='healthinfo'
            control={control}
            required={false}
            label='Health Info'
            error={!!errors?.healthinfo}
            helperText={<span>{errors?.healthinfo?.message}</span>}
            options={healthinfo_options?.filter((e) => e?.value)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            error={!!errors?.otherhealthinfo}
            helperText={<span>{errors?.otherhealthinfo?.message}</span>}
            style={{ width: "100%" }}
            size='small'
            label='Other health info'
            {...register("otherhealthinfo")}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ControlSelect
            name='havedrown'
            control={control}
            required
            label='Ever drown?'
            error={!!errors?.havedrown}
            helperText={<span>{errors?.havedrown?.message}</span>}
            options={have_drown_options?.filter((e) => e?.value)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ControlSelect
            name='permission'
            control={control}
            required
            label='Photograph permission'
            error={!!errors?.permission}
            helperText={<span>{errors?.permission?.message}</span>}
            options={photo_permission}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ControlCheckBoxGroup
            name='findus'
            control={control}
            required={false}
            label='Found us by'
            error={!!errors?.findus}
            helperText={<span>{errors?.findus?.message}</span>}
            options={findus_options?.filter((e) => e?.value)}
          />
        </Grid>

        {watch("findus")?.includes("Friend") ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              error={!!errors?.friendname}
              helperText={<span>{errors?.friendname?.message}</span>}
              style={{ width: "100%" }}
              size='small'
              label='Friend name'
              {...register("friendname")}
            />
          </Grid>
        ) : null}

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            error={!!errors?.otherfindus}
            helperText={<span>{errors?.otherfindus?.message}</span>}
            style={{ width: "100%" }}
            size='small'
            label='Found us by other'
            {...register("otherfindus")}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <LoadingButton
            style={{ width: "100%" }}
            className='ssc_text_field'
            size='small'
            variant='contained'
            disableElevation
            loading={updateLoading}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </LoadingButton>
        </Grid>
      </Grid>
    </ControlDrawer>
  );
}

export default UpdateStudent;
