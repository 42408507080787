import ExpiredStorage from "expired-storage";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { USER_LOCAL_STORAGE_KEY } from "../../constants";
import { User } from "../../recoils";

export function useExpiredStorage() {
  const [auth, setAuth] = useRecoilState(User.userSelector);
  const [isAlert, setIsAlert] = useRecoilState(User.haveBeenAlertedAtom);

  // check if user is expired
  const expiredStorage = new ExpiredStorage();
  const expiredKeys = expiredStorage.clearExpired();

  useEffect(() => {
    const expiredAt = expiredStorage.getTimeLeft(USER_LOCAL_STORAGE_KEY);

    const toMS = expiredAt * 1000 + (expiredAt ? 2000 : 0);
    const minusFiveMinutes = (expiredAt - 120) * 1000;
    // to show message
    setTimeout(() => {
      if (!isAlert && minusFiveMinutes > 0) {
        setIsAlert(true);
      }
    }, minusFiveMinutes);
    // auto logout
    setTimeout(() => {
      setAuth(null);
    }, toMS);

    if (expiredKeys?.find((e) => e === USER_LOCAL_STORAGE_KEY)) {
      setAuth(null);
    }
  }, [expiredKeys?.length, auth?.username]);

  return { expiredKeys };
}
