import { Box, LinearProgress } from "@mui/material";
import React from "react";

function ControlLinearLoading() {
  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgress />
    </Box>
  );
}

export default ControlLinearLoading;
