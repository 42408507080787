import React, { useEffect, useRef, useState } from "react";
import "./Static.scss";
import { useRequest } from "ahooks";
import CalculateAge from "calculate-age";
import { onConfirm } from "react-confirm-pro";
import moment from "moment";
import { GrMapLocation } from "react-icons/gr";
import { MdPhoneIphone } from "react-icons/md";
import { GiBank } from "react-icons/gi";
import { FaPrint } from "react-icons/fa";
import { useReactToPrint } from "react-to-print";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { PuffLoader, MoonLoader } from "react-spinners";
import { NumericFormat } from "react-number-format";
import Switch from "react-switch";
import { useRecoilValue } from "recoil";

import { Invoice as InvoiceDB } from "../../hooks/model";
import RowAccounting from "./rowaccounting/RowAccounting";
import { calculateDiscountVat, closeTab } from "../../utils/function";
import InvoiceLogo from "../../assets/images/invoice-logo.png";
import CheckBoxImage from "../../assets/images/checked.png";
import UnCheckBoxImage from "../../assets/images/unchecked.png";
import Signature from "./signature/Signature";
import Footer from "./footer/Footer";
import ErrorDisplay from "./error/ErrorDisplay";
import { User as UserAuth } from "../../recoils";
import Banks from "./banks/Banks";
// import { roundRielCurrency } from "../../utils/format";

const defaultOptions = {
  title: <h3>Are you sure?</h3>,
  description: <p>Do you really want to delete this invoice?</p>,
};

function ControlInvoice() {
  const auth = useRecoilValue(UserAuth.userState);

  const [search] = useSearchParams();
  const studentId = search.get("studentId");
  const invoiceId = search.get("invoiceId");
  const courseId = search.get("courseId");

  const [invoiceData, setInvoiceData] = useState(null);
  const [courseData, setCourseData] = useState(null);
  const [paymentType, setPaymentType] = useState("cash");
  const [rielExchangeRate, setRielExchangeRate] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [remark, setRemark] = useState(0);
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [invoiceTime, setInvoiceTime] = useState(null);
  const [customerVATTIN, setCustomerVATTIN] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [coursePer, setCoursePer] = useState(null);
  const [invoiceCourse, setInvoiceCourse] = useState(null);
  const [vatRate, setVatRate] = useState(0);

  const [isEditMode, setIsEditMode] = useState(false);
  const [isOrganization, setIsOrganization] = useState(false);

  const [isShowStamp, setIsShowStamp] = useState(true);

  const printRef = useRef();
  const calculatedData = calculateDiscountVat(grandTotal, discount, vatRate, rielExchangeRate);

  const { loading, run: fetchData } = useRequest(InvoiceDB.getByStudent, {
    onSuccess: (res) => {
      if (res?.status) {
        setInvoiceData(res?.data);
        setCourseData(res?.course);
      }
    },
    defaultParams: [{ studentId, invoiceId, courseId }],
  });

  const fetchInvoice = () => {
    fetchData({ studentId, invoiceId, courseId });
  };

  const { loading: loadingSave, run: runSave } = useRequest(InvoiceDB.update, {
    manual: true,
    onSuccess: (res) => {
      if (res?.status) {
        fetchInvoice();
        toast.success(res?.message);
        setIsEditMode(false);
      }
    },
  });

  const { loading: loadingPay, run: runPayment } = useRequest(InvoiceDB.updatePaid, {
    manual: true,
    onSuccess: (res) => {
      if (res?.status) {
        fetchInvoice();
        toast.success(res?.message);
        setIsEditMode(false);
      }
    },
  });

  const { loading: loadingDelete, run: runDelete } = useRequest(InvoiceDB.delete, {
    manual: true,
    onSuccess: (res) => {
      if (res?.status) {
        closeTab();
      }
    },
  });

  useEffect(() => {
    setPaymentType(invoiceData?.pay_type);
    setDiscount(parseFloat(invoiceData?.discount));
    setRemark(invoiceData?.inv_remark);
    setCustomerVATTIN(invoiceData?.customerVATTIN);

    setRielExchangeRate(
      parseInt(invoiceData?.rielRate, 10) > 0 ? parseInt(invoiceData?.rielRate, 10) : 4000
    );
    setVatRate(
      parseInt(invoiceData?.vatRate, 10) > 0 ? parseInt(invoiceData?.vatRate, 10) / 100 : 0
    );

    setIsOrganization(parseInt(invoiceData?.is_organization, 10) > 0);
    setIsShowStamp(parseInt(invoiceData?.show_stamp, 10) > 0);

    setStartDate(moment().format("YYYY-MM-DD"));
    setEndDate(moment().add(1, "months").format("YYYY-MM-DD"));

    setInvoiceDate(moment().format("YYYY-MM-DD"));
    setDueDate(moment().add(1, "months").format("YYYY-MM-DD"));

    if (invoiceData?.end_date !== "0000-00-00" && invoiceData?.start_date !== "0000-00-00") {
      setStartDate(invoiceData?.start_date);
      setEndDate(invoiceData?.end_date);

      setInvoiceDate(invoiceData?.start_date);
      setDueDate(invoiceData?.end_date);
    }

    if (invoiceData?.created !== "0000-00-00" && invoiceData?.due !== "0000-00-00") {
      setInvoiceDate(invoiceData?.created);
      setDueDate(invoiceData?.due);
    }

    setInvoiceTime(moment().format("YYYY-MM-DD HH:mm:ss"));
    if (invoiceData?.time !== "00:00:00") {
      setInvoiceTime(`${invoiceData?.created} ${invoiceData?.time}`);
    }

    setCoursePer(invoiceData?.per);
    setInvoiceCourse(invoiceData?.course);
    setGrandTotal(parseFloat(invoiceData?.price));

    if (invoiceData?.status !== "paid") {
      setCoursePer(courseData?.per);
      setInvoiceCourse(`${courseData?.type} (${courseData?.day}) ${courseData?.duration}`);
      setRemark(invoiceData?.remark);

      setGrandTotal(parseFloat(courseData?.price));

      return;
    }

    setStartDate(invoiceData?.i_startdate);
    setEndDate(invoiceData?.i_enddate);
    if (invoiceData?.paid_date === "") {
      setStartDate(invoiceData?.created);
      setEndDate(invoiceData?.due);
    }
  }, [invoiceData, courseData]);

  const getStudentAge = () => {
    if (invoiceData?.birthdate > moment().format("YYYY-MM-DD")) {
      return "0Y";
    }

    const age = invoiceData?.birthdate
      ? new CalculateAge(invoiceData?.birthdate, moment().format("YYYY-MM-DD")).getObject()
      : null;

    return `${age?.years}Y`;
  };

  const printInvoice = useReactToPrint({
    content: () => printRef.current,
  });

  const handlePrint = async () => {
    if (isEditMode) {
      toast.warn("Please save before print!");
      return;
    }

    printInvoice();
  };

  const handleSave = () => {
    let newInvoiceData = {
      studentId,
      invoiceId,
      created: invoiceDate,
      due: dueDate,
      payType: paymentType,
      time: moment(invoiceTime).format("HH:mm:ss"),
      discount,
      remark,
      status: invoiceData?.status,
      startDate,
      endDate: dueDate,
      per: coursePer,
      rielRate: rielExchangeRate,
      vatRate: vatRate * 100,
      customerVATTIN,
      show_stamp: isShowStamp ? 1 : 0,
    };

    if (
      invoiceData?.status === "paid"
      // && auth?.post?.toUpperCase() === "ADMIN"
    ) {
      newInvoiceData = {
        ...newInvoiceData,
        price: grandTotal,
        course: invoiceCourse,
      };
    }

    runSave({
      ...newInvoiceData,
    });
  };

  const handlePaid = (payment) => {
    runPayment({
      studentId,
      invoiceId,
      created: invoiceDate,
      due: dueDate,
      payType: paymentType,
      time: moment(invoiceTime).format("HH:mm:ss"),
      discount,
      remark,
      course: invoiceCourse,
      status: invoiceData?.status,
      startDate,
      endDate: dueDate,
      per: coursePer,
      payment,
      rielRate: rielExchangeRate,
      vatRate: vatRate * 100,
      customerVATTIN,
      price: grandTotal,
    });
  };

  const handleDelete = () => {
    onConfirm({
      ...defaultOptions,
      type: "dark",
      btnSubmit: "Yes",
      btnCancel: "No",
      keyboardEvents: {
        escape: true,
        submit: true,
      },
      onSubmit: () => {
        runDelete({ invoiceId });
      },
      onCancel: () => {},
    });
  };

  const handleVAT = (newVat) => {
    if (!isEditMode) {
      toast.warn("Please click edit to change!");
      return;
    }

    const currentVat = vatRate * 100;

    if (currentVat !== newVat) {
      setVatRate(parseFloat(newVat / 100));
      return;
    }

    setVatRate(0);
  };

  const handleShowStamp = () => {
    if (!isEditMode) {
      toast.warn("Please click edit to change!");
      return;
    }

    setIsShowStamp((prev) => !prev);
  };

  const handleSetPaymentType = (type) => {
    if (!isEditMode) {
      toast.warn("Please click edit to change!");
      return;
    }
    setPaymentType(type);
  };

  const getInvoiceId = () => {
    if (
      invoiceData?.invoice_id !== "" ||
      moment(invoiceData?.createdAt).format("YYYY-MM-DD") > "2022-06-30"
    ) {
      return invoiceData?.invoice_id;
    }

    if (invoiceData?.createdAt === "0000-00-00 00:00:00") {
      return `SSC${moment(invoiceData?.created).format("YY")}${invoiceData?.in_id
        ?.toString()
        .padStart(6, "0")}`;
    }

    return `SSC${moment(invoiceData?.createdAt).format("YY")}${invoiceData?.in_id
      ?.toString()
      .padStart(6, "0")}`;
  };

  const getCourse = () => {
    if (courseId) {
      return invoiceData?.status === "pending" ? (
        <button
          title='Paid'
          type='button'
          disabled={loadingPay}
          onClick={() => handlePaid("paid")}
          className='btn-print'
        >
          {loadingPay ? <MoonLoader color='#FFFFFF' size={15} /> : "Paid"}
        </button>
      ) : (
        <button
          title='Unpay'
          type='button'
          disabled={loadingPay}
          onClick={() => handlePaid("pending")}
          className='btn-print'
        >
          {loadingPay ? <MoonLoader color='#FFFFFF' size={15} /> : "Unpay"}
        </button>
      );
    }

    return null;
  };

  if (loading)
    return (
      <div className='loading-container'>
        <PuffLoader color='#FFFFFF' />
      </div>
    );

  if (!invoiceData)
    return (
      <ErrorDisplay
        description={
          <>
            <h3>Something wrong!</h3>Please make sure you click from invoice.
          </>
        }
      />
    );

  if (!courseData && invoiceData?.status !== "paid")
    return (
      <ErrorDisplay
        description={
          <>
            <h3>Something wrong!</h3>Please make sure student have course.
          </>
        }
      />
    );

  return (
    <div style={{ backgroundColor: "#6c757d" }}>
      <div className='button-container'>
        {!isEditMode ? (
          <button
            title='Edit'
            type='button'
            onClick={() => setIsEditMode(true)}
            className='btn-print'
          >
            Edit
          </button>
        ) : (
          <button
            title='Save'
            type='button'
            className='btn-print'
            disabled={loadingSave}
            onClick={handleSave}
          >
            {loadingSave ? <MoonLoader color='#FFFFFF' size={15} /> : "Save"}
          </button>
        )}

        {getCourse()}

        <button title='Print' type='button' onClick={handlePrint} className='btn-print'>
          <FaPrint fontSize={17} />
        </button>

        {auth?.post?.toUpperCase() === "ADMIN" && (
          <button
            onClick={handleDelete}
            type='button'
            disabled={loadingDelete}
            className='btn-delete'
          >
            {loadingDelete ? <MoonLoader color='#FFFFFF' size={15} /> : "Delete"}
          </button>
        )}

        <button
          type='button'
          onClick={() => handleVAT(10)}
          className='btn-vat'
          style={{ width: 110 }}
        >
          <Switch
            onChange={() => {}}
            checked={vatRate * 100 === 10}
            value={vatRate * 100 === 10}
            className='react-switch'
            id='disabled-switch'
            height={15}
            width={40}
          />
          &nbsp;
          <span>VAT 10%</span>
        </button>

        <button
          type='button'
          onClick={() => handleVAT(15)}
          className='btn-vat'
          style={{ width: 110 }}
        >
          <Switch
            onChange={() => {}}
            checked={vatRate * 100 === 15}
            value={vatRate * 100 === 15}
            className='react-switch'
            id='disabled-switch'
            height={15}
            width={40}
          />
          &nbsp;
          <span>VAT 15%</span>
        </button>

        <button type='button' className='btn-cross' onClick={closeTab}>
          Close
        </button>
      </div>

      {isOrganization && invoiceData?.status === "paid" && (
        <div className='button-container'>
          <button
            type='button'
            onClick={handleShowStamp}
            className='btn-vat'
            style={{ width: 110 }}
          >
            <Switch
              onChange={() => {}}
              checked={isShowStamp}
              value={isShowStamp}
              className='react-switch'
              id='disabled-switch'
              height={15}
              width={40}
            />
            &nbsp;
            <span>Stamp</span>
          </button>
        </div>
      )}

      <div className='invoice-box bg-white ' ref={printRef}>
        <div className='invoice-content-block'>
          {invoiceData?.status === "paid" && isShowStamp && (
            <div className='box-status-opacity' style={{ top: vatRate > 0 ? 575 : 551 }}>
              PAID
              <br />
              <span id='paid-date'>{moment(invoiceData?.paid_date).format("DD/MMM/YYYY")}</span>
            </div>
          )}

          <table cellPadding={0} cellSpacing={0} style={{ fontSize: "13px" }}>
            <tbody>
              <tr className='top'>
                <td colSpan={2} style={{ padding: "0px" }}>
                  <table>
                    <tbody>
                      <tr>
                        <td className='title' style={{ paddingBottom: "0px", width: "32%" }}>
                          <img
                            alt='ssc-invoice-logo'
                            src={InvoiceLogo}
                            className='rounded-circle'
                            style={{ height: "85px", maxHeight: "85px" }}
                          />
                        </td>
                        <td style={{ textAlign: "left", padding: "0px" }}>
                          <h1
                            className='header-text'
                            style={{ fontSize: "25px", marginLeft: "0px", fontWeight: "normal" }}
                          >
                            សឺវ៉ាយវ៉ល ស្វីម ក្លឹប
                          </h1>
                          <h2 style={{ marginLeft: "19px" }}>SURVIVAL SWIM CLUB</h2>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td colSpan={6}>
                  <hr className='line-head line' style={{ borderTop: "4px solid #000" }} />
                </td>
              </tr>
            </tbody>
          </table>
          <table cellPadding={0} cellSpacing={0} style={{ fontSize: "13px" }}>
            <tbody>
              <tr className='information'>
                <td colSpan={6} style={{ width: "75%!important" }}>
                  <span className='info-text-kh'>
                    <b>
                      <GiBank /> លេខអត្តសញ្ញាណកម្មសារពើពន្ធ(TIN) ៖ E116-2200002319
                    </b>
                  </span>
                  <br />

                  <span className='info-text-kh'>
                    <GrMapLocation /> ភូមិវត្តបូព៌ សង្កាត់សាលាកំរើក ក្រុងសៀមរាប ខេត្តសៀមរាប
                  </span>
                  <br />
                  <span className='info-text'>
                    &emsp; Wat Bo Village, Sangkat Sala Kamreuk, Siem Reap Municipality, Siem Reap
                    Province
                  </span>
                  <br />
                  <span className='info-text'>
                    <MdPhoneIphone /> +855(0)12 797 085 / srsurvivalswim@gmail.com / FB: Survival
                    Swim Club
                  </span>
                  <br />
                  <br />

                  <Banks />
                </td>
                <td style={{ textAlign: "center" }} />
              </tr>
            </tbody>
          </table>
          <table cellPadding={0} cellSpacing={0} style={{ fontSize: "13px" }}>
            <tbody>
              <tr>
                <td colSpan={6}>
                  <hr className='line' style={{ margin: "0px" }} />
                </td>
              </tr>
              <tr>
                <td colSpan={6} style={{ textAlign: "center" }}>
                  <span className='header-text' style={{ fontSize: "17px" }}>
                    វិក្កយប័ត្រ
                  </span>
                  <br />
                  <span className='info-text' style={{ fontWeight: "bold", fontSize: "20px" }}>
                    Invoice
                  </span>
                  <br />
                </td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>
                  <span className='info-text-kh'>អតិថិជន</span> /{" "}
                  <span className='info-text'>Customer</span> :&nbsp;
                  <b className='info-text-kh'>
                    {`${
                      invoiceData?.st_prefix !== "" && !isOrganization
                        ? `${invoiceData?.st_prefix}.`
                        : ""
                    } ${invoiceData?.st_name}`}
                    {!isOrganization && `, ${getStudentAge(invoiceData?.birthdate)}`}
                  </b>
                  <br />
                  <span className='info-text-kh'>អាស័យដ្ឋាន</span> /{" "}
                  <span className='info-text'>Address</span> :{" "}
                  <span className='info-text-kh'>{invoiceData?.address}</span>
                  <br />
                  <span className='info-text-kh'>ទូរស័ព្ទលេខ</span> /{" "}
                  <span className='info-text'>Tel</span> :{" "}
                  <span className='info-text-kh'>{invoiceData?.contactnumber}</span>
                  <br />
                  <span className='info-text-kh'>
                    លេខអត្តសញ្ញាណកម្មសារពើពន្ធ(VATTIN) :&nbsp;
                    {isEditMode ? (
                      <input
                        type='text'
                        className='info-text-kh'
                        onChange={(e) => setCustomerVATTIN(e.target.value)}
                        value={customerVATTIN}
                      />
                    ) : (
                      <span className='info-text-kh'>{customerVATTIN}</span>
                    )}
                  </span>
                </td>
                <td style={{ textAlign: "left", width: "40%" }}>
                  <span className='info-text-kh'>លេខរៀងវិក្កយប័ត្រ</span> /{" "}
                  <span className='info-text'>
                    Invoice N<sup style={{ fontSize: "10px" }}>o</sup>
                  </span>{" "}
                  :&nbsp;
                  <span style={{ color: "red" }} className='info-text-kh'>
                    {getInvoiceId()}
                  </span>
                  <br />
                  <span className='info-text-kh'>ម៉ោង</span> /{" "}
                  <span className='info-text'>
                    Time : {moment(`${invoiceTime}`).format("hh:mm a")}
                  </span>
                  <br />
                  <span className='info-text-kh'>កាលបរិច្ឆេទ</span> /{" "}
                  <span className='info-text'>Issued Date</span> :&nbsp;
                  <span id='e-invoice-date'>
                    {invoiceData?.paid_date !== ""
                      ? moment(invoiceData?.paid_date).format("DD/MMM/YYYY")
                      : null}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <table cellPadding={0} cellSpacing={0} style={{ fontSize: "13px" }}>
            <tbody>
              <tr className='heading'>
                <td>
                  <span className='info-text-kh'>បរិយាយមុខទំនិញឬសេវាកម្ម</span> /
                  <span className='info-text'>Description of Goods or Services</span>
                </td>
                <td>
                  <span className='info-text-kh'>តម្លៃ</span> /
                  <span className='info-text'>Price</span>
                </td>
              </tr>
              <tr className='item'>
                <td style={{ width: "90%" }}>
                  {isEditMode &&
                  invoiceData?.status === "paid" &&
                  auth?.post?.toUpperCase() === "ADMIN" ? (
                    <input
                      type='text'
                      onChange={(e) => setInvoiceCourse(e.target.value)}
                      value={invoiceCourse}
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <span id='course'>{invoiceCourse}</span>
                  )}
                </td>
                <td style={{ width: "10%" }}>
                  {isEditMode &&
                  invoiceData?.status === "paid" &&
                  auth?.post?.toUpperCase() === "ADMIN" ? (
                    <input
                      type='number'
                      onChange={(e) => setGrandTotal(parseFloat(e.target.value))}
                      value={grandTotal}
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <span>
                      ${parseFloat(grandTotal).toFixed(2)}/{coursePer}
                    </span>
                  )}
                </td>
              </tr>
              <tr className='item'>
                <td>
                  {moment(startDate).format("DD-MMM-YYYY")} -{" "}
                  {moment(endDate).format("DD-MMM-YYYY")}
                  <br />
                </td>
                <td />
              </tr>
              <tr className='item'>
                <td colSpan={2}>
                  {isEditMode ? (
                    <input
                      type='text'
                      id='edit-remark-input'
                      onChange={(e) => setRemark(e.target.value)}
                      value={remark}
                      style={{ width: "100%" }}
                      placeholder='remark..'
                    />
                  ) : (
                    <span id='e-remark'>{remark}</span>
                  )}
                  <br />
                </td>
              </tr>
            </tbody>
          </table>

          <table cellPadding={0} cellSpacing={0} style={{ fontSize: "13px" }}>
            <tbody>
              <tr>
                <td style={{ width: "30%" }}>
                  <span
                    role='button'
                    onClick={() => handleSetPaymentType("cash")}
                    onKeyUp={() => {}}
                    tabIndex={0}
                  >
                    <img
                      alt='ssc'
                      src={paymentType === "cash" ? CheckBoxImage : UnCheckBoxImage}
                      className='checkpay'
                      data-id='chb_cash'
                      id='chb_cash_box'
                    />
                    <label
                      className='form-check-label checkpay'
                      data-id='chb_cash'
                      htmlFor='chb_cash'
                    >
                      {" "}
                      Cash
                    </label>
                  </span>
                  &nbsp;&nbsp;&nbsp;
                  <span
                    role='button'
                    onClick={() => handleSetPaymentType("aba")}
                    onKeyUp={() => {}}
                    tabIndex={0}
                  >
                    <img
                      alt='ssc'
                      src={paymentType === "aba" ? CheckBoxImage : UnCheckBoxImage}
                      className='checkpay'
                      data-id='chb_aba'
                      id='chb_aba_box'
                    />
                    <label
                      className='form-check-label checkpay'
                      data-id='chb_aba'
                      htmlFor='chb_aba'
                    >
                      {" "}
                      ABA
                    </label>
                  </span>
                  &nbsp;&nbsp;&nbsp;
                  <span
                    role='button'
                    onClick={() => handleSetPaymentType("acleda")}
                    onKeyUp={() => {}}
                    tabIndex={0}
                  >
                    <img
                      alt='ssc'
                      src={paymentType === "acleda" ? CheckBoxImage : UnCheckBoxImage}
                      className='checkpay'
                      data-id='chb_acleda'
                      id='chb_acleda_box'
                    />
                    <label
                      className='form-check-label checkpay'
                      data-id='chb_acleda'
                      htmlFor='chb_acleda'
                    >
                      {" "}
                      ACLEDA
                    </label>
                  </span>
                  <br />
                  <span
                    role='button'
                    onClick={() => handleSetPaymentType("hattha")}
                    onKeyUp={() => {}}
                    tabIndex={0}
                  >
                    <img
                      alt='ssc'
                      src={paymentType === "hattha" ? CheckBoxImage : UnCheckBoxImage}
                      className='checkpay'
                      data-id='chb_hattha'
                      id='chb_hattha_box'
                    />
                    <label
                      className='form-check-label checkpay'
                      data-id='chb_hattha'
                      htmlFor='chb_hattha'
                    >
                      {" "}
                      HATTHA
                    </label>
                  </span>
                </td>

                <td>
                  <table cellPadding={0} cellSpacing={0} style={{ fontSize: "13px" }}>
                    <tbody>
                      <RowAccounting
                        title='Discount'
                        value={
                          isEditMode ? (
                            <input
                              type='number'
                              style={{ width: "100%" }}
                              onWheel={(e) => e.target.blur()}
                              min={0}
                              onChange={(e) =>
                                setDiscount(
                                  parseFloat(e.target.value !== "" ? e.target.value : 0, 10)
                                )
                              }
                              value={discount}
                              placeholder='% Dis'
                            />
                          ) : (
                            <span id='discount-show'>${discount}</span>
                          )
                        }
                      />

                      {/* vat rate show */}
                      <RowAccounting
                        title={
                          <span className='info-text-kh'>
                            សរុបរួមអាករបន្ថែម / Grand Total w VAT included ($)
                          </span>
                        }
                        value={
                          <span className='totalPrice'>
                            {calculatedData?.grandTotal.toFixed(2)}
                          </span>
                        }
                      />

                      <RowAccounting
                        title={
                          <span className='info-text-kh'>អត្រាប្តូរប្រាក់ / Exchange Rate</span>
                        }
                        value={
                          isEditMode ? (
                            <input
                              type='number'
                              style={{ width: "100%" }}
                              onWheel={(e) => e.target.blur()}
                              min={0}
                              onChange={(e) =>
                                setRielExchangeRate(
                                  parseInt(e.target.value !== "" ? e.target.value : 0, 10)
                                )
                              }
                              value={rielExchangeRate}
                              placeholder='xxxxxx'
                            />
                          ) : (
                            <span className='totalPrice info-text-kh'>
                              <NumericFormat
                                value={rielExchangeRate}
                                displayType='text'
                                thousandSeparator
                                renderText={(value, props) => <div {...props}>{value}&#6107;</div>}
                              />
                            </span>
                          )
                        }
                      />

                      <RowAccounting
                        title={
                          <span className='info-text-kh'>
                            សរុបរួមអាករបន្ថែម / Grand Total w VAT included (&#6107;)
                          </span>
                        }
                        value={
                          <span className='totalPrice info-text-kh'>
                            <NumericFormat
                              // value={roundRielCurrency(calculatedData?.rielGrandTotal)}
                              value={calculatedData?.rielGrandTotal.toFixed(2)}
                              displayType='text'
                              thousandSeparator
                              renderText={(value, props) => <div {...props}>{value}&#6107;</div>}
                            />
                          </span>
                        }
                      />
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>

          <br />
          <br />
          <Signature />
        </div>

        <Footer />
      </div>
    </div>
  );
}

export default ControlInvoice;
