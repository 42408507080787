import ExpiredStorage from "expired-storage";
import { selector } from "recoil";
import { USER_LOCAL_STORAGE_KEY } from "../../constants";
import { userState } from "./atom";

export const userSelector = selector({
  key: "userSelector",
  get: ({ get }) => get(userState),
  set: ({ set }, state) => {
    const expiredStorage = new ExpiredStorage();
    const expires = 86400; // 24 hours
    // const expires = 120;

    expiredStorage.setJson(USER_LOCAL_STORAGE_KEY, state, expires);

    if (!state) {
      expiredStorage.removeItem(USER_LOCAL_STORAGE_KEY);
    }

    set(userState, state);
  },
});
