import Init from "./init";

class User {
  static getByPage = async ({ page, pageSize, keyword }) => {
    const data = { page, pageSize, keyword };
    const res = await Init.fnInit("/api/users/getPaginator", data);

    return res;
  };

  static add = async ({ username, password, position }) => {
    const data = { username, password, position };
    const res = await Init.fnInit("/api/users/create", data);

    return res;
  };

  static addCount = async ({ userId }) => {
    const data = { userId };
    const res = await Init.fnInit("/api/users/countIn", data);

    return res;
  };

  static update = async ({ id, username, password, position }) => {
    const data = { id, username, password, position };
    const res = await Init.fnInit("/api/users/update", data);

    return res;
  };

  static login = async ({ username, password }) => {
    const data = { username, password };
    const res = await Init.fnInit("/api/users/login", data);

    return res;
  };

  static delete = async ({ id }) => {
    const data = { id };
    const res = await Init.fnInit("/api/users/delete", data);

    return res;
  };
}

export default User;
