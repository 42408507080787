import { Box, Grid } from "@mui/material";
import React from "react";
import TotalNumber from "../shared/totalNumber";

function TotalBox({ dataSource }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <Box elevation={0} mb={2}>
          <TotalNumber title='Total swimmer' total={dataSource?.totalDoc} />
          {dataSource?.totalByLocation?.map((load) => (
            <TotalNumber key={load?.id} title={load?.location} total={load?.totalStudent} />
          ))}
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <Box elevation={0} mb={2}>
          <TotalNumber
            title='Paid'
            total={<span style={{ color: "#2e7d32" }}>{dataSource?.totalPaid}</span>}
          />
          <TotalNumber
            title='Pending'
            total={<span style={{ color: "#d32f2f" }}>{dataSource?.totalPending}</span>}
          />
          <TotalNumber title='Male' total={dataSource?.totalMale} />
          <TotalNumber title='Female' total={dataSource?.totalFemale} />
        </Box>
      </Grid>
    </Grid>
  );
}

export default TotalBox;
