import { Box, Drawer } from "@mui/material";
import React from "react";

import "./Static.scss";

function ControlDrawer({ open, setOpen, title, children }) {
  return (
    <Drawer anchor='right' className='custom-drawer' open={open} onClose={() => setOpen(false)}>
      <Box role='presentation' p={2} className='drawer-header'>
        {title}
      </Box>
      <Box role='presentation' p={2} className='drawer-container'>
        {children}
      </Box>
    </Drawer>
  );
}

export default ControlDrawer;
