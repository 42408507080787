import {
  FormControl,
  FormLabel,
  FormControlLabel,
  FormHelperText,
  FormGroup,
  Checkbox,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

// import "./Static.scss";

function ControlCheckBoxGroup({ control, name, error, helperText, required, label, options }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl required={required} fullWidth size='small' error={error}>
          <FormLabel>{label}</FormLabel>
          <FormGroup
            row
            required={required}
            value={value}
            // onChange={(e) => {
            //   console.log(e.target.value);
            //   onChange([e.target.value]);
            // }}
          >
            {options?.map((load) => (
              <FormControlLabel
                key={load?.value}
                name={load?.value}
                value={load?.value}
                control={
                  <Checkbox
                    checked={value?.includes(load?.value)}
                    onChange={(option, checked) => {
                      if (checked) {
                        onChange([...value, load?.value]);
                        return null;
                      }

                      const updatedValue = value?.filter((e) => e !== load?.value);

                      onChange([...updatedValue]);
                      return null;
                    }}
                    value={load?.value}
                  />
                }
                label={load?.label}
              />
            ))}
          </FormGroup>
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      )}
    />
  );
}

export default ControlCheckBoxGroup;
