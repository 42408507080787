import Init from "./init";

class Location {
  static getByPage = async ({ page, pageSize, keyword }) => {
    const data = { page, pageSize, keyword };
    const res = await Init.fnInit("/api/locations/getPaginator", data);

    return res;
  };

  static getAll = async () => {
    const res = await Init.fnInit("/api/locations/getAll");

    return res;
  };

  static add = async ({ location, location_kh, color }) => {
    const data = { location, location_kh, color };
    const res = await Init.fnInit("/api/locations/create", data);

    return res;
  };

  static update = async ({ id, location, location_kh, color }) => {
    const data = { id, location, location_kh, color };
    const res = await Init.fnInit("/api/locations/update", data);

    return res;
  };

  static delete = async ({ id }) => {
    const data = { id };
    const res = await Init.fnInit("/api/locations/delete", data);

    return res;
  };
}

export default Location;
