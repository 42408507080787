import React from "react";
// import Skeleton from "react-loading-skeleton";
import { Skeleton, Typography } from "@mui/material";
import { motion } from "framer-motion";

import "./CustomTable.scss";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";

function CustomTable({
  columns = [],
  dataSource = [],
  rowKeys,
  isLoading,
  noHeaders,
  className,
  rowClassNames,
  skeletonCount,
  onDoubleClick = () => {},
  footer = null,
}) {
  function TableHeaders() {
    return !noHeaders ? (
      <thead className='hidden md:table-header-group'>
        <tr className='border-b-2 h-14'>
          {/* <th className={` -top-5 bg-left-nav-dark z-50 w-10`}>No</th> */}
          {columns?.map((load) => (
            <th
              className={`${load?.fixed ? "column-sticky" : ""}`}
              key={`${load?.key}`}
              style={load?.width && { minWidth: load?.width }}
            >
              {load?.title}
            </th>
          ))}
        </tr>
      </thead>
    ) : null;
  }

  function TableBody() {
    if (dataSource?.length <= 0) {
      return (
        <tr>
          <td
            style={{ height: 100, textAlign: "center", color: "#cccccc" }}
            colSpan={columns?.length}
          >
            <HiOutlineDocumentDuplicate size={50} />
            <Typography>Empty data!</Typography>
          </td>
        </tr>
      );
    }

    return dataSource?.map((load) => (
      <motion.tr
        key={`${rowKeys(load) || ""}`}
        className={`${rowClassNames ? rowClassNames(load) : ""}`}
        onDoubleClick={() => onDoubleClick(load)}
        style={{ cursor: "pointer" }}
        whileTap={{
          opacity: 0.3,
        }}
        transition={{
          duration: 0.2,
          type: "spring",
        }}
      >
        {columns?.map((load1) =>
          load1.render ? (
            <td
              className={`${load1?.fixed ? "column-sticky" : ""}`}
              key={`${load1?.key}`}
              style={load1?.width && { minWidth: load1?.width }}
            >
              {load1.render(load)}
            </td>
          ) : (
            <td
              className={`${load1?.fixed ? "column-sticky" : ""}`}
              key={`${load1?.key}`}
              style={load1?.width && { minWidth: load1?.width }}
            >
              {load[load1?.key]}
            </td>
          )
        )}
      </motion.tr>
    ));
  }

  return (
    <div className='ssc_custom_table'>
      <table className={className}>
        <TableHeaders />
        <tbody>
          {isLoading ? (
            [...Array(dataSource?.length || skeletonCount || 20)]?.map(() => (
              <tr key={Math.random()}>
                {columns?.map((load1) => (
                  <td
                    // className={`${load1?.fixed ? "column-sticky" : ""}`}
                    key={`${load1?.key}`}
                    style={load1?.width && { minWidth: load1?.width, padding: 8 }}
                  >
                    {/* <Skeleton height={20} /> */}
                    <Skeleton variant='text' animation='wave' height={30} />
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <TableBody />
          )}
          {footer && footer(dataSource)}
        </tbody>
      </table>
    </div>
  );
}

export default CustomTable;
