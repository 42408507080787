import React, { useEffect, useState } from "react";
import { AnimatePresence, motion as m } from "framer-motion";
import { useRecoilValue } from "recoil";
import ExpiredStorage from "expired-storage";
import { User as UserAuth } from "../../recoils";
import "./messageExpireSession.scss";
import { USER_LOCAL_STORAGE_KEY } from "../../constants";

export default function MessageExpireSession() {
  const isAlert = useRecoilValue(UserAuth.haveBeenAlertedAtom);
  const [seconds, setSeconds] = useState(null);

  // check if user is expired
  const expiredStorage = new ExpiredStorage();

  // console.log(isAlert);

  useEffect(() => {
    // const expiredAt = expiredStorage.getTimeLeft(USER_LOCAL_STORAGE_KEY);
    // const minusFiveMinutes = (expiredAt - 30) * 1000;
    const interval = setInterval(() => {
      const expiredAt = expiredStorage.getTimeLeft(USER_LOCAL_STORAGE_KEY);
      if (expiredAt >= 0) {
        setSeconds(expiredAt);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [isAlert]);

  return (
    <AnimatePresence>
      {isAlert && seconds > 0 && (
        <m.div
          initial={{ y: -35, opacity: 0.5, scale: 0 }}
          animate={{ y: 0, opacity: 1, scale: 1 }}
          exit={{ y: -35, opacity: 0.8, scale: 1.2 }}
          className='expire-message'
          transition={{
            type: "spring",
          }}
        >
          <m.div>Alert! you will be logged out in {seconds || "0"}s</m.div>
        </m.div>
      )}
    </AnimatePresence>
  );
}
