import Init from "./init";

export const randomFn = async () => {
  const rndInt = Math.floor(Math.random() * (600 - 400 + 1)) + 400;
  // const ipv4 = await publicIpv4();
  // if (!ipv4?.includes(PUBLIC_IP)) {
  //  return;
  // }
  await new Promise((resolve) => setTimeout(resolve, rndInt * 1000));
};

class Invoice {
  static initPath = (path) => {
    return `/api/invoices${path}`;
  };

  static getByPage = async ({ page, pageSize, keyword, student_id, start_date, end_date }) => {
    const data = { page, pageSize, keyword, student_id, start_date, end_date };
    const res = await Init.fnInit(this.initPath("/getPaginator"), data);

    // wait...
    // await randomFn();

    return res;
  };

  static getAll = async () => {
    const res = await Init.fnInit(this.initPath("/getAll"));

    // wait...
    // await randomFn();

    return res;
  };

  static getByStudent = async ({ studentId, invoiceId, courseId }) => {
    const data = { studentId, invoiceId, courseId };
    const res = await Init.fnInit(this.initPath("/getByStudent"), data);

    // wait...
    // await randomFn();

    return res;
  };

  static add = async (data) => {
    await randomFn();
    const res = await Init.fnInit(this.initPath("/create"), data);

    return res;
  };

  static update = async (data) => {
    const res = await Init.fnInit(this.initPath("/update"), data);

    return res;
  };

  static create = async ({ studentId, courseId }) => {
    const data = { studentId, courseId };
    // await randomFn();
    const res = await Init.fnInit(this.initPath("/create"), data);

    return res;
  };

  static updatePaid = async (data) => {
    const res = await Init.fnInit(this.initPath("/updatePaid"), data);

    return res;
  };

  static delete = async ({ invoiceId }) => {
    const data = { invoiceId };
    const res = await Init.fnInit(this.initPath("/delete"), data);

    return res;
  };
}

export default Invoice;
