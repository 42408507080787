import { FormControl, FormHelperText, Select, MenuItem, InputLabel } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

// import "./Static.scss";

function ControlSelect({
  control,
  name,
  error,
  helperText,
  required,
  label,
  options = [],
  placeholder,
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl required={required} fullWidth size='small' error={error}>
          <InputLabel>{label}</InputLabel>
          <Select
            placeholder={placeholder || null}
            label={label}
            required={required}
            onChange={onChange}
            value={value || ""}
          >
            {options.map((load) => (
              <MenuItem value={load?.value} key={load?.value}>
                {load?.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      )}
    />
  );
}

export default ControlSelect;
