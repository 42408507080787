import { LoadingButton } from "@mui/lab";
import { Grid, IconButton, Typography, Stack } from "@mui/material";
import moment from "moment";
import React from "react";
import { BsCalendarPlus } from "react-icons/bs";
import { IoCloseCircle } from "react-icons/io5";
import * as yup from "yup";
import {
  ControlDropDatePicker,
  ControlSelect,
  PrimaryModal,
  SelectCourse,
  SelectLocation,
} from "../../../components";
import {
  age_options,
  class_types,
  findus_options,
  genders,
  have_drown_options,
  healthinfo_options,
  photo_permission,
  status_options,
} from "../../../constants";
import { useSetSearchParams } from "../../../hooks";

const initialState = {
  // invoiceNumber: "",
  // studentId: null,
  // registerDate: null,
  // expireDate: null,
  // poolId: null,
  // instructorId: 0,
  // standById: null,
  // time: null,
  // remark: "",
  // postpone: false,

  start_date: null,
  end_date: null,
  st_gender: "",
  classType: "",
  age: "",
  health: "",
  drown: "",
  status: "",
  is_allow_photo: "",
  find_us: "",
  location_id: null,
  course_id: null,
};

const schema = yup
  .object({
    // studentId: yup.object().nullable().required("Student is required!"),
    // // invoiceNumber: yup.string().required("Invoice ID is required"),
    // poolId: yup.object().nullable().required("Pool is required!"),

    // time: yup.object().nullable().required("Time is required!"),
    // standById: yup.object().nullable().required("Stand by is required!"),

    start_date: yup.date().nullable(),
    end_date: yup.date().nullable(),
    st_gender: yup.string(),
    classType: yup.string(),
    age: yup.string(),
    health: yup.string(),
    drown: yup.string(),
    status: yup.string(),
    is_allow_photo: yup.string(),
    find_us: yup.string(),
  })
  .required();

function FilterModal({ setOpen, open }) {
  // const {
  //   control,
  //   watch,
  //   // register,
  //   // reset,
  //   handleSubmit,
  //   formState: { errors },
  //   // setValue,
  // } = useForm({
  //   resolver: yupResolver(schema),
  //   defaultValues: { ...initialState },
  // });

  const {
    handleSetSearch,
    watch,
    control,
    handleSubmit,
    resetSearch,
    formState: { errors },
  } = useSetSearchParams({
    initialState,
    schema,
  });

  const checkKeyName = (key, value) => {
    let newValue = {};

    switch (key) {
      case "start_date":
        newValue[key] = moment(value).format("YYYY-MM-DD");
        break;
      case "end_date":
        newValue[key] = moment(value).format("YYYY-MM-DD");
        break;
      case "location_id":
        newValue[key] = value?.id;
        newValue = { ...newValue, location_label: value?.label };
        break;
      case "course_id":
        newValue[key] = value?.id;
        newValue = { ...newValue, course_label: value?.label };
        break;
      default:
        newValue[key] = value;
        break;
    }
    // if (key === "start_date" || key === "end_date") {
    //   newValue[key] = moment(value).format("YYYY-MM-DD");
    // }

    return newValue;
  };

  const onSubmit = (data) => {
    const keysData = Object.keys(data);

    let value = {};
    keysData?.forEach((load) => {
      if (data[load]) {
        const newValue = checkKeyName(load, data[load]);
        value = { ...value, ...newValue };
      }
    });

    handleSetSearch({
      key: Object.keys(value),
      value,
    });
    setOpen(false);
  };

  return (
    <PrimaryModal
      open={open}
      setOpen={setOpen}
      title={
        <Grid container spacing={2}>
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <Typography variant='h5' gutterBottom component='div'>
              <BsCalendarPlus /> Filter Swimmer Report
            </Typography>
            <Typography variant='subtitle2' gutterBottom component='div'>
              Filter data.
            </Typography>
          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2}>
            <Stack direction='row' justifyContent='flex-end' alignItems='center'>
              <IconButton color='error' onClick={() => setOpen(false)}>
                <IoCloseCircle />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <ControlDropDatePicker name='start_date' label='' control={control} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <ControlDropDatePicker
            name='end_date'
            label=''
            disabled={!watch("start_date")}
            minDate={watch("start_date") || null}
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <ControlSelect
            name='st_gender'
            control={control}
            label='Gender'
            error={!!errors?.st_gender}
            helperText={<span>{errors?.st_gender?.message}</span>}
            options={genders}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <ControlSelect
            name='classType'
            control={control}
            label='Class type'
            error={!!errors?.classType}
            helperText={<span>{errors?.classType?.message}</span>}
            options={class_types}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <ControlSelect
            name='age'
            control={control}
            label='Age'
            error={!!errors?.age}
            helperText={<span>{errors?.age?.message}</span>}
            options={age_options}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <ControlSelect
            name='health'
            control={control}
            label='Health'
            error={!!errors?.health}
            helperText={<span>{errors?.health?.message}</span>}
            options={healthinfo_options}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <ControlSelect
            name='drown'
            control={control}
            label='Drown'
            error={!!errors?.drown}
            helperText={<span>{errors?.drown?.message}</span>}
            options={have_drown_options}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <ControlSelect
            name='status'
            control={control}
            label='Status'
            error={!!errors?.status}
            helperText={<span>{errors?.status?.message}</span>}
            options={status_options}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <ControlSelect
            name='is_allow_photo'
            control={control}
            label='Photo permission'
            error={!!errors?.is_allow_photo}
            helperText={<span>{errors?.is_allow_photo?.message}</span>}
            options={photo_permission}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <ControlSelect
            name='find_us'
            control={control}
            label='Find us'
            error={!!errors?.find_us}
            helperText={<span>{errors?.find_us?.message}</span>}
            options={findus_options}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <SelectLocation name='location_id' label='' control={control} disableClearable={false} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <SelectCourse
            name='course_id'
            control={control}
            required={false}
            label='Class'
            error={!!errors?.course_id}
            helperText={<span>{errors?.course_id?.message}</span>}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={2} lg={2}>
          <LoadingButton
            style={{ width: "100%" }}
            className='ssc_text_field'
            size='small'
            variant='contained'
            color='error'
            disableElevation
            onClick={() => {
              setOpen(false);
              resetSearch();
            }}
          >
            RESET
          </LoadingButton>
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={10}>
          <LoadingButton
            style={{ width: "100%" }}
            className='ssc_text_field'
            size='small'
            variant='contained'
            disableElevation
            onClick={handleSubmit(onSubmit)}
          >
            FILTER
          </LoadingButton>
        </Grid>
      </Grid>
    </PrimaryModal>
  );
}

export default FilterModal;
