import {
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

// import "./Static.scss";

function ControlRadioGroup({ control, name, error, helperText, required, label, options }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl required={required} fullWidth size='small' error={error}>
          <FormLabel>{label}</FormLabel>
          <RadioGroup
            row
            required={required}
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          >
            {options?.map((load) => (
              <FormControlLabel
                key={load?.value}
                value={load?.value}
                control={<Radio value={load?.value} />}
                label={load?.label}
              />
            ))}
          </RadioGroup>
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      )}
    />
  );
}

export default ControlRadioGroup;
