import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { timeSelect } from "../../utils/function";

function SelectTime({ control, name, error, helperText, required }) {
  const time = timeSelect();

  const dataSelect = time?.map((e) => ({ label: e, value: e }));

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          disablePortal
          id='combo-box-student'
          value={value}
          onChange={(option, e) => {
            onChange(e);
          }}
          options={dataSelect}
          isOptionEqualToValue={(option, e) => option?.value === e?.value}
          getOptionLabel={(option) => option.label}
          style={{ width: "100%" }}
          size='small'
          renderInput={(params) => (
            <TextField
              required={required}
              error={error}
              helperText={helperText}
              {...params}
              label='Time'
            />
          )}
        />
      )}
    />
  );
}

export default SelectTime;
