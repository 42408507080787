import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { useRequest } from "ahooks";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { GiConfirmed } from "react-icons/gi";
import { toast } from "react-toastify";

import { Student as StudentDB } from "../../hooks/model";
import "./Static.scss";
import { StudentDetail } from "../../common";
import { BreadCrumb, ControlDialog, ControlMenu } from "../../components";

function NewStudentProfile() {
  const navigate = useNavigate();
  const { studentId } = useParams();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const { loading, data } = useRequest(StudentDB.getOne, {
    defaultParams: [{ studentId }],
    cacheKey: `newStudentProfileCache-${studentId}`,
  });
  const studentData = data?.data;

  const reqSuccess = (res) => {
    if (res?.status) {
      toast.success(res?.message);
      setOpenConfirm(false);
      setOpenDelete(false);
      navigate("/new");
    }
  };
  // run all mutation
  const { loading: loadingConfirm, run: runConfirm } = useRequest(StudentDB.confirm, {
    manual: true,
    onSuccess: (res) => reqSuccess(res),
  });
  const { loading: loadingDelete, run: runDelete } = useRequest(StudentDB.deleteNew, {
    manual: true,
    onSuccess: (res) => reqSuccess(res),
  });

  const menuItems = [
    {
      id: "1",
      title: "Confirm",
      icon: <GiConfirmed color='green' />,
      action: () => setOpenConfirm(true),
    },
    {
      id: "2",
      title: "Delete",
      icon: <MdDelete color='red' />,
      action: () => setOpenDelete(true),
    },
  ];

  return (
    <Box>
      <ControlDialog
        open={openConfirm}
        setOpen={setOpenConfirm}
        isLoading={loadingConfirm}
        title='Confirm'
        type='success'
        description={
          <>
            Do you want to confirm student <b>{studentData?.st_name}</b>?
          </>
        }
        handleYes={() => runConfirm({ studentId: studentData?.student_id })}
      />
      <ControlDialog
        open={openDelete}
        setOpen={setOpenDelete}
        isLoading={loadingDelete}
        title='Delete'
        type='delete'
        description={
          <>
            Do you want to delete student <b>{studentData?.st_name}</b>?
          </>
        }
        handleYes={() => runDelete({ studentId: studentData?.student_id })}
      />

      <Box className='ssc_profile_container' style={{ padding: 25 }}>
        <Grid container style={{ marginBottom: 25 }}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <BreadCrumb
              pageTitle={`NEW STUDENT / ${studentData?.st_name?.toUpperCase()}`}
              isLoading={loading}
            />
          </Grid>
          <Grid item xs={0} sm={6} md={6} lg={6} />
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box className='student-profile-detail' position='relative'>
              <Box className='option-btn'>
                <ControlMenu menuItems={menuItems} />
              </Box>

              <Box style={{ marginTop: 15 }}>
                {!loading ? (
                  <Typography className='student-name' variant='h5' gutterBottom>
                    {studentData?.st_name}{" "}
                    {/* <Chip
                      label={studentData?.st_status}
                      color={studentData?.st_status === "Active" ? "success" : "error"}
                    /> */}
                  </Typography>
                ) : (
                  <Skeleton variant='text' width={300} height={40} />
                )}
              </Box>
              <Box style={{ marginTop: 20 }}>
                <StudentDetail dataSource={studentData} loading={loading} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default NewStudentProfile;
