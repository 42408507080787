import moment from "moment";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import imageCompression from "browser-image-compression";
import CalculateAge from "calculate-age";

export const goToTop = (ref) => {
  // ref.scrollIntoView({ behavior: "smooth", block: "start" });
  ref.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
};

export function keyMenu(e) {
  const myArr = e.split("/");
  let x = "";

  if (myArr[1] === "schedule") {
    x = "schedule";
  } else if (myArr[1] === "report") {
    x = "report";
  } else if (myArr[1] === "user") {
    x = "user";
  } else if (myArr[1] === "new") {
    x = "new";
  } else if (myArr[1] === "swimmer") {
    x = "swimmer";
  } else if (myArr[1] === "deactivated") {
    x = "deactivated";
  } else if (myArr[1] === "invoice") {
    x = "invoice";
  } else if (myArr[1] === "feedback") {
    x = "feedback";
  } else if (myArr[1] === "instructor") {
    x = "instructor";
  } else if (myArr[1] === "course") {
    x = "course";
  } else if (myArr[1] === "pool") {
    x = "pool";
  } else if (myArr[1] === "location") {
    x = "location";
  } else if (myArr[1] === "view-invoice") {
    x = "view-invoice";
  } else if (myArr[1] === "new-feedback") {
    x = "new-feedback";
  } else if (myArr[1] === "feedback-instructor") {
    x = "feedback-instructor";
  } else if (myArr[1] === "feedback-service") {
    x = "feedback-service";
  } else if (myArr[1] === "feedback-fee") {
    x = "feedback-fee";
  } else if (myArr[1] === "feedback-pool") {
    x = "feedback-pool";
  } else if (myArr[1] === "swimmer-report") {
    x = "swimmer-report";
  } else if (myArr[1] === "certificate") {
    x = "certificate";
  } else if (myArr[1] === "staff-card") {
    x = "staff-card";
  } else if (myArr[1] === "student-card") {
    x = "student-card";
  } else {
    x = "";
  }

  return x;
}

export const timeSelect = () => {
  const data = [];
  for (let i = 6; i <= 20; i += 1) {
    for (let j = 0; j < 4; j += 1) {
      const min = j <= 1 ? "00" : "30";
      const minTo = j === 0 || j === 3 ? "30" : "00";

      const hourTo = j > 0 ? i + 1 : i;

      const time = `${i}:${min}-${hourTo}:${minTo}`;

      data.push(time);
    }
  }

  return data;
};

export const subStrRemark = (remark) => {
  if (remark?.length > 25) {
    return `${remark?.substr(0, 25)}..`;
  }
  return remark;
};

export const menuPaperProps = {
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};

export const getFormattedTime = (datetime) => {
  return moment(datetime).format("hh:mmA");
};

export function getCookie(cname) {
  return Cookies.get(cname);
}

export function delete_cookie(name) {
  Cookies.remove(name);
}

export function setCookie(c_name, value, exdays) {
  Cookies.set(c_name, value, { expires: exdays });
}

export const closeTab = () => {
  // window.open(window.location, '_self').close()
  // delete_cookie("is_logged");
  window.top.close();
};

export const getVAT = (price, vatRate) => {
  const vat = (price * vatRate) / 100;

  return vat;
};

export const calculateDiscountVat = (total, discount, vatRate, rielRate) => {
  const propVat = vatRate;
  const vat = (total - discount) * propVat;
  const grandTotal = total - discount + vat;

  return {
    grandTotal: parseFloat(grandTotal),
    vat,
    rielGrandTotal: grandTotal * rielRate,
  };
};

export const checkObjectValue = (object) =>
  Object.entries(object).every(([key, value]) => !["", "null", "undefined"].includes(key) && value);

export const openNewTab = ({ url }) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

export const isImageType = (file) => {
  const imageFile = file;
  if (imageFile?.type?.split("/")[0] !== "image") {
    toast.error("Not image file!");
    return false;
  }

  return true;
};

export const compressImage = async (file) => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  const compressedFile = await imageCompression(file, options);

  const newFile = new File([compressedFile], `${file?.name?.split(".")[0]}.png`, {
    type: "image/png",
  });

  return newFile;
};

export const generateAge = ({ birthdate }) => {
  const today = moment().format("YYYY-MM-DD");
  if (birthdate > today) return null;

  const age = birthdate ? new CalculateAge(birthdate, today).getObject() : null;

  return age;
};

export const addNumberOrderToArray = ({ array = [], page, pageSize }) => {
  if (page && pageSize) {
    return array?.map((record, index) => {
      const pageAdd = page > 1 ? page * pageSize - pageSize + 1 : 1;

      return { ...record, no: index + pageAdd };
    });
  }

  return array?.map((record, index) => {
    return { ...record, no: index + 1 };
  });
};
