import { fetchData } from "../../services/db";

class Init {
  static dbInit = () => {
    const params = new FormData();
    params.append("db_user", process.env.React_App_DB_USER);
    params.append("db_password", process.env.React_App_DB_PASSWORD);
    params.append("db", process.env.React_App_DB);

    return params;
  };

  static fnInit = async (path, data) => {
    const params = this.dbInit();
    params.append("data", JSON.stringify(data));
    const res = await fetchData(path, params);

    return res;
  };

  static fnInitWithFile = async (path, data, file) => {
    const params = this.dbInit();
    const { fileName, fileObject } = file;
    params.append("data", JSON.stringify(data));
    params.append(fileName, fileObject);

    const res = await fetchData(path, params);

    return res;
  };
}

export default Init;
