import Init from "./init";

class Instructor {
  static initPath = (path) => {
    return `/api/instructors${path}`;
  };

  static getByPage = async ({ page, pageSize, keyword, location_id }) => {
    const data = { page, pageSize, keyword, location_id };
    const res = await Init.fnInit(this.initPath("/getPaginator"), data);

    return res;
  };

  static getAll = async () => {
    const res = await Init.fnInit(this.initPath("/getAll"));

    return res;
  };

  static add = async ({
    name,
    name_kh,
    gender,
    position,
    position_kh,
    inst_user,
    inst_pass,
    location_id,
    phone_number,
    private_indoor_fee,
    private_outdoor_fee,
    join_three_pax,
    join_five_pax,
    aba_number,
    is_staff,
    salary,
  }) => {
    const data = {
      name,
      name_kh,
      gender, // select
      position, // input string
      position_kh, // input string kh
      inst_user,
      inst_pass,
      location_id,
      phone_number,
      private_indoor_fee, // input number
      private_outdoor_fee, // input number
      join_three_pax, // input number
      join_five_pax, // input number
      aba_number, // input string
      is_staff, // checkbox or switch, if true = 1, false = 0
      salary, // if is_staff === false disabled
    };
    const res = await Init.fnInit(this.initPath("/create"), data);

    return res;
  };

  static update = async (data) => {
    const res = await Init.fnInit(this.initPath("/update"), data);

    return res;
  };

  static getOne = async ({ instId }) => {
    const data = { instId };
    const res = await Init.fnInit(this.initPath("/getOne"), data);

    return res;
  };

  static generateCard = async ({ instId, image, old_image, expireDate }) => {
    const data = { instId, old_image, expireDate };
    const file = { fileName: "image", fileObject: image };
    const res = await Init.fnInitWithFile(this.initPath("/generateCard"), data, file);

    return res;
  };

  static delete = async ({ id }) => {
    const data = { id };
    const res = await Init.fnInit(this.initPath("/delete"), data);

    return res;
  };
}

export default Instructor;
