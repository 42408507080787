import React from "react";

function Footer() {
  return (
    <div className='invoice-footer'>
      <table style={{ fontSize: "10px" }}>
        <tbody>
          <tr className='note'>
            <td colSpan={2} style={{ width: "50%" }}>
              <span className='header-text'>សម្គាល់ ៖</span>
              <span className='info-text-kh'>
                {" "}
                ច្បាប់ដើមសម្រាប់អ្នកទិញ ច្បាប់ចម្លងសម្រាប់អ្នកលក់ / រាល់ការទូទាត់ហើយ
                មិនអាចដកប្រាក់វិញបានទេ
              </span>
              <br />
              <span className='info-text'>
                Note : Orginal invoice for customer, copied invoice for seller / All payment are
                non-refundable.
              </span>{" "}
              <br />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Footer;
