import Init from "./init";

class Feedback {
  static initPath = (path) => {
    return `/api/feedbacks${path}`;
  };

  static getByPage = async ({
    page,
    pageSize,
    keyword,
    start_date,
    end_date,
    location_id,
    instructor_id,
    pool_id,
    campaign,
  }) => {
    const data = {
      page,
      pageSize,
      keyword,
      start_date,
      end_date,
      location_id,
      instructor_id,
      pool_id,
      campaign,
    };
    const res = await Init.fnInit(this.initPath("/getPaginator"), data);

    return res;
  };

  static getAll = async () => {
    const res = await Init.fnInit(this.initPath("/getAll"));

    return res;
  };

  static getNew = async ({ page, pageSize, keyword }) => {
    const data = { page, pageSize, keyword };
    const res = await Init.fnInit(this.initPath("/getNew"), data);

    return res;
  };

  static getInstructorPaginator = async ({
    page,
    pageSize,
    keyword,
    instructor_id,
    location_id,
    average,
  }) => {
    const data = { page, pageSize, keyword, instructor_id, location_id, average };
    const res = await Init.fnInit(this.initPath("/getInstructorPaginator"), data);

    return res;
  };

  static getPoolPaginator = async ({ page, pageSize, keyword, pool_id, location_id, average }) => {
    const data = { page, pageSize, keyword, pool_id, location_id, average };
    const res = await Init.fnInit(this.initPath("/getPoolPaginator"), data);

    return res;
  };

  static getFeePaginator = async ({ page, pageSize, keyword, average }) => {
    const data = { page, pageSize, keyword, average };
    const res = await Init.fnInit(this.initPath("/getFeePaginator"), data);

    return res;
  };

  static getServicePaginator = async ({
    page,
    pageSize,
    keyword,
    start_date,
    end_date,
    location_id,
    campaign,
    average,
  }) => {
    const data = {
      page,
      pageSize,
      keyword,
      location_id,
      average,
      start_date,
      end_date,
      campaign,
    };
    const res = await Init.fnInit(this.initPath("/getServicePaginator"), data);

    return res;
  };

  static getOne = async ({ f_id }) => {
    const data = { f_id };
    const res = await Init.fnInit(this.initPath("/getOne"), data);

    return res;
  };

  static getTotalNew = async () => {
    const res = await Init.fnInit(this.initPath("/getTotalNew"));

    return res;
  };

  static confirm = async ({ f_id }) => {
    const data = { f_id };
    const res = await Init.fnInit(this.initPath("/confirm"), data);

    return res;
  };

  static add = async (data) => {
    const res = await Init.fnInit(this.initPath("/create"), data);

    return res;
  };

  static update = async (data) => {
    const res = await Init.fnInit(this.initPath("/update"), data);

    return res;
  };

  static delete = async ({ f_id }) => {
    const data = { f_id };
    const res = await Init.fnInit(this.initPath("/delete"), data);

    return res;
  };
}

export default Feedback;
