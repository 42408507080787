import { Fab, Zoom } from "@mui/material";
import React from "react";
import { AiOutlineClear } from "react-icons/ai";
import { useRecoilValue } from "recoil";
import { ResetButton } from "../../recoils";

function FloabButton() {
  const resetState = useRecoilValue(ResetButton.resetButtonState);

  const fabStyle = {
    position: "absolute",
    bottom: 16,
    right: 16,
  };

  return (
    <Zoom
      key='primary'
      in={resetState.isIn}
      timeout={{
        enter: 200,
        exit: 200,
      }}
      style={{
        transitionDelay: `${resetState.isIn ? 200 : 0}ms`,
      }}
      unmountOnExit
    >
      <Fab sx={fabStyle} onClick={resetState.action} aria-label='reset' color='primary'>
        <AiOutlineClear size={20} />
      </Fab>
    </Zoom>
  );
}

export default FloabButton;
