import {
  Avatar,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import * as yup from "yup";
import { BsFileLock } from "react-icons/bs";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { motion as m } from "framer-motion";
import { useKeyPress, useRequest } from "ahooks";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useSetRecoilState } from "recoil";
import { User as UserAuth } from "../../recoils";
import { User as UserDB } from "../../hooks/model";

const initialState = {
  username: "",
  password: "",
};

const schema = yup
  .object({
    username: yup.string().required("Username is required"),
    password: yup.string().required("Password is required"),
  })
  .required();

function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { ...initialState },
  });

  const [showPassword, setShowPassword] = useState(false);

  const setAuth = useSetRecoilState(UserAuth.userSelector);
  const setIsAlert = useSetRecoilState(UserAuth.haveBeenAlertedAtom);

  // login
  const { loading, run: onLogin } = useRequest(UserDB.login, {
    manual: true,
    onSuccess: (res) => {
      if (res?.status) {
        setAuth(res?.data);
        setIsAlert(false);
        toast.success("Login successfully!");
      }
    },
  });

  const paperStyle = { padding: 20, height: "fit-content", width: 280, margin: "100px auto" };
  const avatarStyle = { backgroundColor: "#1bbd7e" };
  const btnstyle = { margin: "8px 0" };

  const onSubmit = (values) => {
    onLogin({
      username: values?.username,
      password: values?.password,
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  useKeyPress("enter", handleSubmit(onSubmit));

  return (
    <m.div
      initial={{ y: -300, scale: 0.5, opacity: 0 }}
      animate={{ y: 0, scale: 1, opacity: 1 }}
      transition={{ duration: 0.4, type: "timing" }}
    >
      <Paper elevation={10} style={paperStyle}>
        <Grid align='center'>
          <Avatar style={avatarStyle}>
            <BsFileLock />
          </Avatar>
          <h2>Sign In</h2>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              error={!!errors?.username}
              helperText={<span>{errors?.username?.message}</span>}
              required
              size='small'
              fullWidth
              label='Username'
              {...register("username")}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl
              fullWidth
              variant='outlined'
              size='small'
              required
              error={!!errors?.password}
            >
              <InputLabel htmlFor='outlined-adornment-password'>Password</InputLabel>
              <OutlinedInput
                id='outlined-adornment-password'
                type={showPassword ? "text" : "password"}
                size='small'
                required
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      edge='end'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label='Password'
                {...register("password")}
              />
              <FormHelperText>{errors?.password?.message}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            {/* <FormControlLabel
              control={<Checkbox name='checkedB' color='primary' />}
              label='Remember me'
            /> */}
            <LoadingButton
              loading={loading}
              type='submit'
              color='primary'
              variant='contained'
              onClick={handleSubmit(onSubmit)}
              style={btnstyle}
              fullWidth
            >
              Sign in
            </LoadingButton>
            {/* <Typography>
            <Link to='#'>Forgot password ?</Link>
          </Typography>
          <Typography>
            {" "}
            Do you have an account ?<Link to='#'>Sign Up</Link>
          </Typography> */}
          </Grid>
        </Grid>
      </Paper>
    </m.div>
  );
}

export default Login;
