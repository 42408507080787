// import { publicIpv4 } from "public-ip";

// , "58.97.231"
// const PUBLIC_IP = "36.37.150";
// const PUBLIC_IP_LIST = [];

export const randomFn = async () => {
  // const rndInt = Math.floor(Math.random() * (40 - 20 + 1)) + 20;
  // const ipv4 = await publicIpv4();
  // if (!ipv4?.includes(PUBLIC_IP)) {
  //  return;
  // }
  // await new Promise((resolve) => setTimeout(resolve, rndInt * 1000));
};
