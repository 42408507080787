import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import React from "react";
import { useRecoilState } from "recoil";
import { User as UserState } from "../../recoils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

function Logout({ setOpen, open }) {
  const [, setAuth] = useRecoilState(UserState.userSelector);

  const handleClose = () => {
    setOpen(false);
  };
  const onOk = () => {
    setAuth(null);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      maxWidth='sm'
      onClose={handleClose}
      aria-describedby='alert-dialog-slide-description'
    >
      <DialogTitle>Do you want to logout?</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-slide-description'>
          You will redirect to login page.
          <br />
          Please confirm your decision.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className='ssc_text_field' onClick={handleClose}>
          Cancel
        </Button>
        <Button className='ssc_text_field' onClick={onOk}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Logout;
