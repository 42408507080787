import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

function SelectAverage({ control, name, error, helperText, required, label, averageOption = 0 }) {
  const options = [...Array(averageOption)].map((_, index) => index + 1);
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth error={error} required={required}>
          <InputLabel id='average-select-label'>{label}</InputLabel>
          <Select
            labelId='average-select-label'
            id='average-select-label-options'
            // placeholder='average'
            value={value}
            size='small'
            label={label !== null && label !== undefined ? label : ""}
            onChange={(e) => {
              onChange(e);
            }}
            // renderValue={() => <span style={{ color: "#dddddd" }}>test</span>}
          >
            <MenuItem value=''>---</MenuItem>
            {options.map((load) => (
              <MenuItem value={load} key={load}>
                {load}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      )}
    />
  );
}

export default SelectAverage;
