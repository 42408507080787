import Init from "./init";

class Pool {
  static initPath = (path) => {
    return `/api/pools${path}`;
  };

  static getByPage = async ({ page, pageSize, keyword, location_id }) => {
    const data = { page, pageSize, keyword, location_id };
    const res = await Init.fnInit(this.initPath("/getPaginator"), data);

    return res;
  };

  static getAll = async () => {
    const res = await Init.fnInit(this.initPath("/getAll"));

    return res;
  };

  static add = async ({ pool_name, location_id }) => {
    const data = { pool_name, location_id };
    const res = await Init.fnInit(this.initPath("/create"), data);

    return res;
  };

  static update = async ({ id, pool_name, location_id }) => {
    const data = { id, pool_name, location_id };
    const res = await Init.fnInit(this.initPath("/update"), data);

    return res;
  };

  static delete = async ({ id }) => {
    const data = { id };
    const res = await Init.fnInit(this.initPath("/delete"), data);

    return res;
  };
}

export default Pool;
