import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { useRequest } from "ahooks";
import { useRecoilState } from "recoil";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { complete_lesson_options } from "../../constants";
import { Student as StudentDB } from "../../hooks/model";

import { StudentProfile as StudentProfileRecoil } from "../../recoils";

function CompleteLessonCheck() {
  const [selectValue, setSelectValue] = useState([]);
  const [studentProfile, setStudentProfile] = useRecoilState(
    StudentProfileRecoil.studentProfileState
  );

  useEffect(() => {
    setSelectValue([...studentProfile?.completed_lesson]);
  }, [studentProfile?.completed_lesson]);

  const reqSuccess = (data) => {
    if (data?.status) {
      toast.success(data?.message);
      setStudentProfile((e) => ({ ...e, completed_lesson: [...data?.lesson] }));
      // setSelectValue([...data?.lesson]);
    }
  };

  // please change to create user
  const { run: onUpdate, loading } = useRequest(StudentDB.updateCompleteLesson, {
    manual: true,
    onSuccess: (data) => reqSuccess(data),
  });

  const onSelectValue = ({ value, checked }) => {
    if (checked) {
      setSelectValue((e) => [...e, value]);
      onUpdate({ lesson: [...selectValue, value], studentId: studentProfile?.student_id });
      return null;
    }

    const updatedValue = selectValue?.filter((e) => e !== value);

    setSelectValue([...updatedValue]);
    onUpdate({ lesson: [...updatedValue], studentId: studentProfile?.student_id });
    return null;
  };

  return (
    <FormGroup value={selectValue}>
      {complete_lesson_options?.map((load) => (
        <FormControlLabel
          key={load?.value}
          name={load?.value}
          value={load?.value}
          control={
            <Checkbox
              checked={selectValue?.includes(load?.value)}
              onChange={(option, checked) => onSelectValue({ value: load?.value, checked })}
              value={load?.value}
              disabled={loading}
            />
          }
          label={load?.label}
          style={{ width: "fit-content" }}
        />
      ))}
    </FormGroup>
  );
}

export default CompleteLessonCheck;
