import { Grid, IconButton, Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useRequest } from "ahooks";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IoCloseCircle } from "react-icons/io5";
import * as yup from "yup";

import { toast } from "react-toastify";
import { MdPool } from "react-icons/md";
import { Student as StudentDB } from "../../../hooks/model";
import { PrimaryModal } from "../../../components";

const initialState = {
  instructor_comment: "",
};

const schema = yup
  .object({
    instructor_comment: yup.string(),
  })
  .required();

function UpdateInstComment({ setOpen, open, studentProfile, setStudentProfile }) {
  // inizialize form
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { ...initialState },
  });

  useEffect(() => {
    reset();
    function initFunction() {
      setValue("instructor_comment", studentProfile?.instructor_comment);
    }

    initFunction();
  }, [setValue, reset, studentProfile?.instructor_comment]);

  const reqSuccess = (data) => {
    if (data) {
      toast.success(data?.message);
      setOpen(false);
      setStudentProfile((e) => ({ ...e, instructor_comment: data?.instructor_comment }));
      //   setValue("instructor_comment", studentProfile?.instructor_comment);
    }
  };

  // please change to update student new remark
  const { loading: updateLoading, run: onUpdate } = useRequest(StudentDB.updateInstructorComment, {
    manual: true,
    onSuccess: (data) => reqSuccess(data),
  });

  const onSubmit = (data) => {
    onUpdate({
      instructor_comment: data?.instructor_comment || "",
      studentId: studentProfile?.student_id || 0,
    });
  };

  return (
    <PrimaryModal
      open={open}
      setOpen={setOpen}
      title={
        <Grid container spacing={2}>
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <Typography variant='h5' gutterBottom component='div'>
              <MdPool /> Update Comment
            </Typography>
          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2}>
            <Stack direction='row' justifyContent='flex-end' alignItems='center'>
              <IconButton color='error' onClick={() => setOpen(false)}>
                <IoCloseCircle />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            multiline
            rows={10}
            error={!!errors?.remark}
            helperText={<span>{errors?.remark?.message}</span>}
            style={{ width: "100%" }}
            size='small'
            label='instructor comment'
            {...register("instructor_comment")}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <LoadingButton
            style={{ width: "100%" }}
            className='ssc_text_field'
            size='small'
            variant='contained'
            disableElevation
            loading={updateLoading}
            onClick={handleSubmit(onSubmit)}
          >
            Update
          </LoadingButton>
        </Grid>
      </Grid>
    </PrimaryModal>
  );
}

export default UpdateInstComment;
