import addNotification from "react-push-notification";
import Logo192 from "../assets/logo/logo192.png";

export const pushWebNotification = ({ title, subtitle, message }) => {
  addNotification({
    title,
    subtitle,
    message,
    theme: "darkblue",
    duration: 10000,
    // icon: "https://www.survivalswimclub.com/img/home/Logo-official-2-97-97.png",
    icon: Logo192,
    native: true, // when using native, your OS will handle theming.
  });
};
